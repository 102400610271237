/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin clear-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        top: 12px;
        left: 12px;
        width: 10px;
        height: 14px;
        transform: perspective(10px) rotateX(-10deg);
        border: {
            left: 1px solid var(--header-color);
            right: 1px solid var(--header-color);
            bottom: 1px solid var(--header-color);
            bottom-left-radius: 3px;
            bottom-right-radius: 3px;
        }
    }

    &::after {
        left: 14px;
        top: 7px;
        width: 6px;
        height: 2px;
        box-shadow:
            6px 2px 0 -1px var(--header-color),
            -6px 2px 0 -1px var(--header-color),
            -2px 2px 0 -1px var(--header-color),
            2px 2px 0 -1px var(--header-color);
        border: {
            left: 1px solid var(--header-color);
            right: 1px solid var(--header-color);
            top: 1px solid var(--header-color);
            top-left-radius: 2px;
            top-right-radius: 2px;
        };
    }
}

@mixin share-button-wishlist {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94b";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: var(--primary-dark-color);
        font-weight: normal;
    }
}

@mixin show-password-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94c";
        font-size: 12px;
        line-height: 12px;
        color: var(--table-border-color);
        font-weight: normal;
    }
}

@mixin hide-password-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94d";
        font-size: 16px;
        line-height: 16px;
        color: var(--table-border-color);
        font-weight: normal;
    }
}

@mixin close-button {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94a";
        color: var(--primary-dark-color);
        font-size: 15px;
    }
}

@mixin my-account-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e912";
        color: var(--header-icon-text-color);
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: normal;

        @include before-desktop {
            font-size: 2.5rem;
            color: var(--header-icon-color);
            margin-bottom: 2px;
        }
    }
}

@mixin wishlist-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f08a";
        color: var(--header-icon-text-color);
        font-size: 2rem;
        line-height: 2rem;
        font-weight: normal;

        @include before-desktop {
            font-size: 2.5rem;
            line-height: 2.5rem;
            color: var(--header-icon-color);
            margin-bottom: 2px;
        }
    }
}

@mixin mini-cart-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e902";
        color: var(--header-icon-text-color);
        font-size: 2.5rem;
        line-height: 1.8rem;
        font-weight: normal;

        @include before-desktop {
            font-size: 2.5rem;
            line-height: 2.5rem;
            color: var(--header-icon-color);
            margin-bottom: 2px;
        }
    }
}

@mixin menu-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93c";
        color: var(--header-icon-color);
        font-size: 2.5rem;
        line-height: 2.5rem;
        font-weight: normal;

        @include before-desktop {
            margin-bottom: 2px;
        }
    }
}

@mixin home-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e946";
        color: var(--header-icon-color);
        font-size: 2.5rem;
        line-height: 2.5rem;
        font-weight: normal;

        @include before-desktop {
            margin-bottom: 2px;
        }
    }
}

@mixin slider-left-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e924";
        font-size: 1.7rem;
        line-height: 1.7rem;
        color: $green3;
        font-weight: normal;
    }
}

@mixin slider-right-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e926";
        font-size: 1.7rem;
        line-height: 1.7rem;
        color: $green3;
        font-weight: normal;
    }
}

@mixin menu-right-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e925";
        font-size: 1.1rem;
        line-height: 1.1rem;
        color: $dark3;
        font-weight: normal;
        padding-right: .4rem;
    }
}

@mixin product-compare-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93a";
        font-size: 1.3rem;
        line-height: 1.3rem;
        color: $dark2;
        font-weight: normal;
    }

    &:hover {
        &::before {
            @include desktop {
                color: $default-primary-light-color;
            }
        }
    }
}

@mixin product-wishlist-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f08a";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: $dark2;
        font-weight: normal;
    }

    &:hover {
        &::before {
            @include desktop {
                content: "\f004";
                color: $red3;
            }
        }
    }
}

@mixin product-wishlist-icon-active {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f004";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: $red3;
        font-weight: normal;
    }
}

@mixin header-back-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90e";
        font-size: 1.3rem;
        line-height: 1.3rem;
        color: $dark3;
        font-weight: normal;
    }
}

@mixin menu-back-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e924";
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: $dark3;
        font-weight: normal;
    }
}

@mixin menu-next-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e925";
        font-size: 1.1rem;
        line-height: 1.1rem;
        color: $grey4;
        font-weight: normal;
    }
}

@mixin telephone-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e945";
        font-size: 16.69px;
        line-height: 16.69px;
        color: $white;
        font-weight: normal;
    }
}

@mixin footer-email-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e944";
        font-size: 15px;
        line-height: 15px;
        color: $white;
        font-weight: normal;
    }
}

@mixin down-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e935";
        font-size: .7rem;
        line-height: .7rem;
        color: $dark3;
        font-weight: normal;

        @include mobile {
            margin-right: 1.5rem;
        }
    }
}

@mixin up-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e922";
        font-size: .7rem;
        line-height: .7rem;
        color: $dark3;
        font-weight: normal;
    }
}

@mixin down-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e92f";
        font-size: .7rem;
        line-height: .7rem;
        color: $dark3;
        font-weight: normal;
    }
}

@mixin up-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e930";
        font-size: .7rem;
        line-height: .7rem;
        color: $dark3;
        font-weight: normal;
    }
}

@mixin delete-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93b";
        font-size: 1.8rem;
        line-height: 1.9rem;
        color: $grey13;
        font-weight: normal;
    }

    &:hover {
        &:before {
            @include desktop {
                color: var(--primary-light-color);
            }
        }
    }
}

@mixin discount-coupon-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e943";
        font-size: 1.77rem;
        line-height: 1.77rem;
        color: $grey13;
        font-weight: normal;
        margin-right: 5px;
    }
}

@mixin gift-card-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e915";
        font-size: 1.7rem;
        line-height: 1.7rem;
        color: $grey13;
        font-weight: normal;
        margin-right: 5px;
    }
}

@mixin lock-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e942";
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: $white;
        font-weight: normal;
        margin-right: 1rem;
    }
}

@mixin back-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        width: 18px;
        height: 1px;
        top: 17px;
        right: 8px;
        background-color: var(--header-color);
    }

    &::after {
        width: 8px;
        height: 8px;
        top: 13px;
        left: 10px;
        transform: rotate(-45deg);

        border: {
            top: 1px solid var(--header-color);
            left: 1px solid var(--header-color);
        }
    }
}

@mixin clear-search-button {
    @include close-button;

    border-radius: 50%;
    background-color: var(--search-clear-background);

    height: 15px;
    width: 15px;
    position: absolute;
    right: 2.4rem;

    &::before,
    &::after {
        height: 9px;
        width: 1px;
        top: 3px;
        left: 7px;
        background-color: var(--header-background);
    }
}

@mixin edit-button {
    // border-radius: 10px; <- Wait chrome until bug will be fixed
    box-shadow:
        inset 0 0 0 8px var(--header-background),
        inset 0 0 0 9px var(--header-color);

    &::before,
    &::after {
        content: '';
        position: absolute;
        transform-origin: 0 0;
        transform: rotate(45deg);
        top: 4px;
        right: 2px;
    }

    &::before {
        background-color: var(--header-background);
        box-shadow: 0 0 0 2px var(--header-background);
        width: 4px;
        height: 16px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: {
            top: 1px solid var(--header-color);
            left: 1px solid var(--header-color);
            right: 1px solid var(--header-color);
        }
    }

    &::after {
        background-color: var(--header-color);
        width: 6px;
        top: 4px;
        height: 23px;
        clip-path:
            polygon(
                3px 23px,
                6.5px 17px,
                3px 17px,
                3px 4.5px,
                0 4.5px,
                0 3.5px,
                5px 3.5px,
                5px 4.5px,
                3px 4.5px,
                3px 17px,
                -.5px 17px,
                3px 23px,
                3px 21.5px,
                1px 18px,
                5px 18px,
                3px 21.5px,
            );
    }
}

@mixin lock-button {
    display: inline-block;
    margin-right: 9px;

    &::before,
    &::after {
        content: '';
    }

    &::after {
        display: block;
        position: relative;
        top: 1px;
        width: 10px;
        height: 9px;
        background: currentColor;
        border-radius: 1px;
        box-shadow: inset 0 0 0 -2px var(--button-background);
    }

    &::before {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: -4px;
        border-radius: 50%;
        border: 1px solid currentColor;
        width: 6px;
        height: 6px;
    }
}

@mixin search-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e949";
        color: $white;
        font-size: 20px;
    }
}

@mixin search-close-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94a";
        color: $white;
        font-size: 15px;
    }
}

@mixin facebook-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e904";
        font-size: 15px;
        line-height: 15px;
        color: $white;
        font-weight: normal;
    }
}

@mixin twitter-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90a";
        font-size: 13px;
        line-height: 13px;
        color: $white;
        font-weight: normal;
    }
}

@mixin instagram-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e929";
        font-size: 14px;
        line-height: 14px;
        color: $white;
        font-weight: normal;
    }
}

@mixin youtube-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e934";
        font-size: 11.25px;
        line-height: 11.25px;
        color: $white;
        font-weight: normal;
    }
}

@mixin pinterest-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e948";
        font-size: 15px;
        line-height: 15px;
        color: $white;
        font-weight: normal;
    }
}

@mixin linkedin-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f0e1";
        font-size: 15px;
        line-height: 15px;
        color: $white;
        font-weight: normal;
    }
}

@mixin blog-email-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e928";
        font-size: 13px;
        line-height: 22px;
        color: $dark3;
        font-weight: normal;
    }
}

@mixin whatsapp-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92c";
        font-size: 14px;
        line-height: 22px;
        color: $dark3;
        font-weight: normal;
    }
}

@mixin calendar-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94f";
        font-size: 15px;
        line-height: 15px;
        color: $grey12;
        font-weight: normal;
    }
}

@mixin ios-share {
    $color-dodger-blue: #{$dark3};

    border: 1px solid $color-dodger-blue;
    border-top: 0;
    bottom: -3px;
    height: 3px;
    border-radius: 0 0 3px 3px;
    margin: 0 4px;
    width: 13px;

    &::before {
        content: '';
        border-left: 1px solid $color-dodger-blue;
        border-top: 1px solid $color-dodger-blue;
        display: block;
        height: 6px;
        left: 3px;
        position: relative;
        top: -9px;
        transform: rotate(45deg);
        width: 6px;
    }

    &::after {
        border-left: 1px solid $color-dodger-blue;
        content: '';
        display: block;
        height: 8px;
        left: 6px;
        position: relative;
        top: -15px;
        width: 19px;
    }
}

@mixin base-plus-minus-icon {
    $size: 20px;
    $line-size: 10px;
    $line-bg-color: $white;

    width: $size;
    height: $size;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 1px;
        background-color: $line-bg-color;
        width: $line-size;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

@mixin minus-icon {
    @include base-plus-minus-icon;

    &::after {
        display: none;
    }
}

@mixin plus-icon {
    @include base-plus-minus-icon;
}

@mixin ios-plus {
    $color-storm-grey: #{$dark3};

    background:
        linear-gradient($white, $white),
        linear-gradient($white, $white),
        $color-storm-grey;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 53% 1px, 1px 50%;
    height: 11px;
    margin: 0 4px;
    width: 11px;
}

@mixin share-button {
    $share-button-stroke-width: var(--header-icon-stroke-width);

    background-image: linear-gradient(to right, var(--header-color) 15%, var(--header-background) 0);
    background-repeat: repeat-x;
    background-size: 12px $share-button-stroke-width;
    border-radius: 1px;
    padding: calc(6px - #{$share-button-stroke-width}) calc(8px - #{$share-button-stroke-width});

    border: {
        bottom: $share-button-stroke-width solid var(--header-color);
        left: $share-button-stroke-width solid var(--header-color);
        right: $share-button-stroke-width solid var(--header-color);
    }

    @include desktop {
        background-image: linear-gradient(to right, var(--header-color) 20%, var(--my-account-content-background) 0);
        background-size: 15px $share-button-stroke-width;
        padding: calc(11px - #{$share-button-stroke-width});
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        background-color: var(--header-color);
        height: 10px;
        left: 6px;
        top: -4px;
        width: $share-button-stroke-width;

        @include desktop {
            height: 13px;
            left: 8px;
            top: -6px;
            width: 2px;
        }
    }

    &::after {
        height: 4px;
        left: 4px;
        top: -4px;
        transform: rotate(45deg);
        width: 4px;

        border: {
            left: $share-button-stroke-width solid var(--header-color);
            top: $share-button-stroke-width solid var(--header-color);
        }

        @include desktop {
            height: 6px;
            left: 5px;
            top: -7px;
            width: 6px;
        }
    }
}

@mixin email-icon {
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        box-sizing: border-box;
        border: 1px solid $white;
    }

    &::before {
        width: 18px;
        height: 14px;
        transform: translate(-50%, -50%);
    }

    &::after {
        width: 12px;
        height: 12px;
        transform: rotate(45deg) translate(calc(-50% - 4px), calc(-50% - 4px));
        transform-origin: top left;
        border-top-color: transparent;
        border-left-color: transparent;
    }
}

@mixin compare-icon($color) {
    width: 1px;
    height: 25px;
    background-color: $color;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 7px;
        height: calc(100% - 7px);
        box-sizing: border-box;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 1px;
        box-sizing: border-box;
    }

    &::before {
        left: -7px;
        background-color: $color;
    }

    &::after {
        left: 3px;
        border: 1px solid $color;
        border-left: none;
    }
}

@mixin next-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e608";
        font-size: 21.5px;
        line-height: 21.5px;
        color: $black;
        font-weight: normal;
    }
}

@mixin prev-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e617";
        font-size: 21.5px;
        line-height: 21.5px;
        color: $black;
        font-weight: normal;
    }
}

@mixin next-thin-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e926";
        font-size: 12px;
        line-height: 12px;
        color: $black;
        font-weight: normal;
    }
}

@mixin prev-thin-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e924";
        font-size: 12px;
        line-height: 12px;
        color: $black;
        font-weight: normal;
    }
}

@mixin compare-popup-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e947";
        font-size: 27px;
        line-height: 27px;
        color: $white;
        font-weight: normal;
    }
}

@mixin addtocart-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94e";
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: $green3;
        font-weight: normal;
    }
}

@mixin ar-cube-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e950";
        font-size: 2.8rem;
        line-height: 2.8rem;
        color: $black;
        font-weight: normal;
    }
}

@mixin checkout-lock-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e942";
        font-size: 1.7rem;
        line-height: 1.3rem;
        color: $green6;
        font-weight: normal;
    }
}

@mixin checkout-shipping-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e927";
        font-size: 1.6rem;
        line-height: 2.7rem;
        color: $green2;
        font-weight: normal;
    }
}

@mixin checkout-billing-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92e";
        font-size: 1.2rem;
        line-height: 2.8rem;
        color: $green2;
        font-weight: normal;
    }
}

@mixin order-tracker-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e951";
        color: $white;
        font-size: 2.1rem;
        line-height: 2.1rem;
        font-weight: normal;
    }
}

@mixin order-status-checked {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e919";
        font-size: 1.1rem;
        line-height: 26px;
        color: $white;
        font-weight: normal;
        text-align: center;
    }
}

@mixin copyright-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90c";
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: var(--input-border-color);
        font-weight: normal;
    }
}

@mixin store-address-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e952";
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: var(--primary-base-color);
        font-weight: normal;
    }
}

@mixin store-email-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e944";
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: var(--primary-base-color);
        font-weight: normal;
    }
}

@mixin store-number-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e945";
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: var(--primary-base-color);
        font-weight: normal;
    }
}

@mixin ar-cube-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e950";
        font-size: 2.8rem;
        line-height: 2.8rem;
        color: $black;
        font-weight: normal;
    }
}

@mixin checkout-lock-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e942";
        font-size: 1.7rem;
        line-height: 1.3rem;
        color: $green6;
        font-weight: normal;
    }
}

@mixin checkout-shipping-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e927";
        font-size: 1.6rem;
        line-height: 2.7rem;
        color: $green2;
        font-weight: normal;
    }
}

@mixin checkout-billing-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92e";
        font-size: 1.2rem;
        line-height: 2.8rem;
        color: $green2;
        font-weight: normal;
    }
}

@mixin order-tracker-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e951";
        color: $white;
        font-size: 2.1rem;
        line-height: 2.1rem;
        font-weight: normal;
    }
}

@mixin order-close-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e932";
        color: $white;
        font-size: 1.3rem;
        line-height: 2.5rem;
        font-weight: normal;
    }
}

@mixin order-status-checked {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e919";
        font-size: 1.1rem;
        line-height: 26px;
        color: $white;
        font-weight: normal;
        text-align: center;
    }
}

@mixin lightning-icon-left {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e954";
        font-size: 27px;
        line-height: 27px;
        color: $yellow2;
        font-weight: normal;
        text-align: center;
        margin-right: 2rem;
    }
}

@mixin lightning-icon-right {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e954";
        font-size: 27px;
        line-height: 27px;
        color: $yellow2;
        font-weight: normal;
        text-align: center;
        margin-left: 2rem;
    }
}

@mixin store-locator-location-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e906";
        color: $grey13;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: normal;
    }
}

@mixin clock-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e955";
        color: $grey13;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: normal;
    }
}

@mixin facebook-box-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f230";
        font-size: 15px;
        line-height: 14px;
        font-weight: normal;
    }
}

@mixin google-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e905";
        font-size: 14px;
        line-height: 14px;
        font-weight: normal;
    }
}

@mixin apple-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f179";
        font-size: 15px;
        line-height: 14px;
        font-weight: normal;
    }
}

@mixin website-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e956";
        color: $grey13;
        font-size: 15px;
        line-height: 15px;
        font-weight: normal;
    }
}

//  Custom Icons
$icon-website: "\e956";
$icon-apple: "\f179";
$icon-facebook-official: "\f230";
$icon-feather-clock: "\e955";
$icon-lightning: "\e954";
$icon-feather-clock: "\e954";
$icon-location1: "\e952";
$icon-order-tracker: "\e951";
$icon-cube: "\e950";
$icon-calendar1: "\e94f";
$icon-success-atc: "\e94e";
$icon-toggle-off: "\e94d";
$icon-eye-reveal: "\e94c";
$icon-share-wishlist: "\e94b";
$icon-search-thin: "\e949";
$icon-close-thin: "\e94a";
$icon-linkedin: "\f0e1";
$icon-pinterest: "\e948";
$icon-star-rating: "\e939";
$icon-home: "\e936";
$icon-menu-m: "\e937";
$icon-virtual: "\e938";
$icon-cart: "\e902";
$icon-location-picker: "\e906";
$icon-secure-checkout: "\e90b";
$icon-my-account: "\e912";
$icon-telephone: "\e914";
$icon-compare: "\e92d";
$icon-email: "\e933";
$icon-youtube: "\e934";
$icon-question-circle: "\f059";
$icon-heart: "\f004";
$icon-star: "\f005";
$icon-star-o: "\f006";
$icon-heart-o: "\f08a";
$icon-phone: "\f095";
$icon-filter1: "\f0b1";
$icon-angle-up: "\e922";
$icon-angle-down: "\e935";
$icon-close-popup: "\e932";
$icon-checkmark1: "\e931";
$icon-down-large: "\e92f";
$icon-up-large: "\e930";
$icon-checkout-payment: "\e92e";
$icon-arrow: "\e923";
$icon-arrow-left-thin: "\e924";
$icon-arrow-right: "\e925";
$icon-arrow-right-thin: "\e926";
$icon-checkout-shipping: "\e927";
$icon-feather-mail: "\e928";
$icon-insta: "\e929";
$icon-minus-large: "\e92a";
$icon-plus-large: "\e92b";
$icon-whatsapp: "\e92c";
$icon-send-email: "\e921";
$icon-minus-faq: "\e91f";
$icon-plus-faq: "\e920";
$icon-checkbox-checked: "\e91d";
$icon-checkbox: "\e91e";
$icon-radio-button-checked: "\e91b";
$icon-radio-button: "\e91c";
$icon-review-star: "\e91a";
$icon-filter-checked: "\e919";
$icon-share: "\e918";
$icon-size-guide: "\e917";
$icon-gift: "\e915";
$icon-cart-large: "\e913";
$icon-lock: "\e916";
$icon-information: "\e910";
$icon-filter-outline: "\e911";
$icon-close-filter: "\e90f";
$icon-angel-next: "\e90d";
$icon-angel-prev: "\e90e";
$icon-copyright: "\e90c";
$icon-rss: "\e909";
$icon-twitter: "\e90a";
$icon-down-arrow: "\e903";
$icon-facebook: "\e904";
$icon-google: "\e905";
$icon-menu-arrow: "\e907";
$icon-minus-icon: "\e908";
$icon-search1: "\e901";
$icon-cart1: "\e900";
$icon-help: "\e623";
$icon-gift-registry: "\e628";
$icon-present: "\e629";
$icon-account: "\e627";
$icon-arrow-up-thin: "\e633";
$icon-arrow-down-thin: "\e626";
$icon-wishlist-full: "\e600";
$icon-wishlist-empty: "\e601";
$icon-warning: "\e602";
$icon-update: "\e603";
$icon-trash: "\e604";
$icon-settings: "\e606";
$icon-pointer-down: "\e607";
$icon-next: "\e608";
$icon-menu: "\e609";
$icon-location: "\e60a";
$icon-list: "\e60b";
$icon-info: "\e60c";
$icon-grid: "\e60d";
$icon-comment-reflected: "\e60e";
$icon-collapse: "\e60f";
$icon-checkmark: "\e610";
$icon-calendar: "\e612";
$icon-arrow-up: "\e613";
$icon-arrow-down: "\e614";
$icon-search: "\e615";
$icon-remove: "\e616";
$icon-prev: "\e617";
$icon-pointer-up: "\e618";
$icon-pointer-right: "\e619";
$icon-pointer-left: "\e61a";
$icon-flag: "\e61b";
$icon-expand: "\e61c";
$icon-envelope: "\e61d";
$icon-compare-full: "\e61e";
$icon-compare-empty: "\e61f";
$icon-comment: "\e620";
$icon-up: "\e621";
$icon-down: "\e622";
$icon-mobile-home: "\e93d";
$icon-mobile-menu: "\e93c";
$icon-USP-1: "\e940";
$icon-USP-2: "\e93f";
$icon-USP-3: "\e941";
$icon-USP-4: "\e93e";
$icon-compare-product: "\e93a";
$icon-remove-product: "\e93b";
$icon-checkout: "\e942";
$icon-discount-coupon: "\e943";
$icon-phone-footer: "\e945";
$icon-mail-footer: "\e944";
$icon-home-mobile-01: "\e946";
$icon-success-big: "\e947";
