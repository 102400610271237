/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

ul,
ol {
    ul,
    ol {
        counter-reset: list;
    }
}

ul {
    li {
        &::before {
            font-family: $font-nunito-sans;
            color: $dark3;
            font-size: 1.8rem;
        }
    }
}

li {
    padding-left: 1.8rem;

    @include mobile {
        position: relative;
    }

    &::before {
        position: absolute;
        left: 0;
        top: .07em;
        padding-right: 1rem;

        ol > & {
            content: counter(list) '.';
        }

        &,
        ul > & {
            content: '\2022';
        }
    }
}
