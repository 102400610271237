/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --button-padding: 12px 22px 11px;
    --button-radius: 2px;
    --button-font-size: 1.2rem;
    --button-letter-space: #{$letter-spacing-m4};
    --button-font-weight: #{$font-weight-bold};
    --button-text-transform: uppercase;

    --button-color: #{$green6};
    --button-background: var(--primary-light-color);
    --button-border: var(--primary-light-color);
    --button-hover-background: var(--primary-base-color);
    --button-hover-color: #{$green6};
    --button-hover-border: var(--primary-base-color);
    --button-border-width: 1px;

    --button-primary-revert-border: var(--primary-light-color);
    --button-primary-revert-background: #{$white};
    --button-primary-revert-color: var(--primary-light-color);
    --button-primary-revert-hover-border: var(--primary-light-color);
    --button-primary-revert-hover-background: var(--primary-light-color);
    --button-primary-revert-hover-color: #{$white};

    --button-secondary-border: var(--primary-dark-color);
    --button-secondary-background: #{$white};
    --button-secondary-color: var(--primary-dark-color);
    --button-secondary-hover-border: var(--primary-dark-color);
    --button-secondary-hover-background: #{$green7};
    --button-secondary-hover-color: #{$white};

    --button-secondary-revert-border: #{$grey7};
    --button-secondary-revert-background: #{$white};
    --button-secondary-revert-color: var(--primary-dark-color);
    --button-secondary-revert-hover-border: #{$grey8};
    --button-secondary-revert-hover-background: #{$grey9};
    --button-secondary-revert-hover-color: var(--primary-dark-color);

    --button-like-link-color: var(--primary-base-color);
    --button-like-link-hover-color: var(--primary-base-color);
    --button-like-link-hover-text-decoration: underline;
    --button-like-link-font-size: 15px;
    --button-like-link-font-weight: #{$font-weight-regular};
    --button-like-link-letter-spacing: #{$letter-spacing-m2};

    --button-like-link-primary-color: var(--primary-dark-color);
    --button-like-link-primary-hover-color: var(--primary-dark-color);
    --button-like-link-primary-hover-text-decoration: underline;

    --button-like-link-secondary-color: #{$grey4};
    --button-like-link-secondary-hover-color: var(--primary-dark-color);
    --button-like-link-secondary-hover-text-decoration: none;
}

.Button {
    @include button;
}

button {
    background-color: transparent;
}
