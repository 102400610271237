/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --input-type-number-width: 80px;
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;
    --primary-divider-color: #{$grey9};
    --primary-error-color: #{$red2};
    --secondary-error-color: #{$red2};
    --secondary-success-color: #{$white};
    --primary-success-color: #{$green3};
    --secondary-info-color: var(--primary-dark-color);
    --primary-info-color: var(--primary-dark-color);
    --body-content-color: var(--primary-dark-color);
    --body-background-color: #{$grey15};
    --notification-background-color: #{$white};
    --notification-error-background-color: #FFFCFC;
    --notification-success-background-color: #FCFFFD;
    --checkout-background-color: #{$white};
    --input-checkbox-border-radius: 2px;
    --input-checkbox-checked-background-color: var(--primary-light-color);
    --input-checkbox-checked-border-color: var(--primary-light-color);
    --disabled-color: #{$white};
    --disabled-background-color: #{$grey13};
    --table-border-color: #{$grey13};
    --content-paragraph-color: #{$dark3};
    --form-label-color: #{$dark2};

    // Declare imported primary colors to change the theme's colors
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-primary-light-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
}
