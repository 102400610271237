/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --popup-background: #0003;

    @include mobile {
        --popup-content-padding: 2rem;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-location-min-width: 95%;
    --popup-content-padding: 2rem 3rem;

    @include after-mobile {
        --popup-min-width: 428px;
        --popup-location-min-width: 428px;
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: stretch;
    }

    @include print-only {
        display: inline-block !important;
        position: static !important;
        background-color: transparent !important;
        width: 100% !important;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;

        @include mobile {
            height: calc(100% - var(--header-total-height));
        }
    }

    &-Container {
        @include mobile {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    &-Heading {
        font-size: 2rem;
        line-height: 3.1rem;
        text-decoration: none;
        text-align: center;
        font-weight: $font-weight-bold;
        text-transform: capitalize;
        color: $dark3;

        @include print-only {
            @include h2;

            color: $dark3 !important;
            margin-bottom: 0 !important;
        }
    }

    &-Header {
        @include mobile {
            display: none;
        }

        @include desktop {
            margin: 1rem auto 3rem;
        }

        @include print-only {
            display: inline-block;
            margin-top: 3rem;
            margin-bottom: 0;
        }
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 5px;
        padding: var(--popup-content-padding);
        min-width: var(--popup-min-width);
        max-width: calc(var(--content-wrapper-width) * .8);
        max-height: 80%;
        max-height: 62.2vh;
        overflow-y: auto;

        @include desktop {
            @include custom-scroll;

            max-width: var(--popup-min-width);
        }

        @include mobile {
            border-radius: 0;
            max-height: 100%;
        }

        @include print-only {
            --popup-content-padding: 0;

            padding: 0;
            max-height: none !important;
            max-width: 100%;
            overflow: visible;
        }

        .FieldForm {
            .Button {
                @include desktop {
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }

    & &-CloseBtn {
        @include close-button;

        height: 35px;
        width: 35px;
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 5;
        background: $green4;
        border-radius: 30px;
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before,
        &::after {
            color: $white;
            top: unset;
        }

        @include mobile {
            top: 25px;
            right: 5px;
            display: none;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    &.ProductCompareAddSuccessPopup,
    &.ProductCompareAddSuccessPopup,
    &.MyAccountCompleteProfilePopup,
    &.MyAccountDeleteAccountPopup,
    &.PayInStoreDisclaimerPopup {
        @include print-only {
            display: none !important;
            height: 0 !important;
            visibility: hidden !important;
        }
    }
}

.SelectLocationPopup {
    align-items: center;

    &_isVisible {
        width: 100%;
        height: 100vh;
    }

    &-title {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 0.7rem;
        font-size: $h5-font-size-desktop;
        line-height: $h5-font-line-height-desktop;
        letter-spacing: $h5-letter-spacing;
    }

    .Popup-Content {
        border-radius: 5px;
        min-width: var(--popup-location-min-width);
        overflow-y: visible;

        @include mobile {
            min-width: var(--popup-location-min-width);
            width: var(--popup-location-min-width);
        }
    }
}

.PageContentPopup {
    .Popup-Content {
        padding: 0;

        @include desktop {
            --popup-min-width: 665px;
        }

        .CmsPage {
            @include mobile {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .CmsPage-Wrapper {
            border: 0;

            @include mobile {
                margin: 0;
            }
            
            @include desktop {
                margin-bottom: 30px;
            }

            .cms-upper-section {
                @include mobile {
                    margin-bottom: 0;
                }
            }
        }

        .CmsPage-Container {
            margin: 0;
        }
    }
}

.scrollDisabled {
    position: fixed;
    margin-top: 0;
    width: 100%;
    overflow-y: scroll;

    @include print-only {
        position: static;
    }
}
