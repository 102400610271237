/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Field {
    margin-top: 20px;

    label {
        font-size: 1.4rem;
        line-height: 2.2rem;
        letter-spacing: $letter-spacing-m2;
        color: var(--form-label-color);
    }

    &-Label {
        font-weight: $font-weight-bold;
        padding-bottom: 1rem;

        &_isDisabled {
            color: var(--form-label-disable-color);
        }

        &_isRequired {
            &::after {
                content: '*';
                color: var(--primary-error-color);
                margin-left: .2rem;
            }
        }
    }

    &-Message {
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 1.4rem;
        letter-spacing: #{$letter-spacing-m};
        font-weight: #{$font-weight-regular};
        color: var(--secondary-error-color);
    }

    &-Note {
        margin-bottom: .24rem;
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--field-note);
        opacity: 0;
        height: 0;
        transition: height .1s ease-in, opacity .1s linear;
        will-change: height, opacity;

        @include mobile {
            margin-bottom: .28rem;
            font-size: 1.4rem;
        }

        &::before {
            content: '* ';
        }
    }
    
    &_hasError {
        input,
        textarea,
        select {
            border: 1px solid var(--primary-error-color);
        }

        .FieldSelect {
            &-Options_isExpanded {
                @include desktop {
                    border-color: var(--primary-error-color);
                    border-top-color: transparent;
                }
            }
        }
    }

    &::placeholder {
        color: var(--field-note);
        font-size: 1.08rem;

        @include mobile {
            font-size: 1.26rem;
        }
    }

    input:focus + .Field-Note {
        opacity: 1;
        height: 100%;
    }

    &_style {
        &_inline {
            $size-mobile: 20px;
            $size: 28px;
            $border-color: #000;
            $input-width-mobile: 35px;
            $input-width: 35px;

            @include desktop {
                $input-width: 50px;
            }

            &.Field {
                display: flex;
                margin: 0;

                button {
                    position: relative;
                    width: $size-mobile;
                    height: $size-mobile;
                    border: solid 1px $border-color;

                    @include after-mobile {
                        width: $size;
                        height: $size;
                    }

                    &:last-of-type {
                        order: -1;
                    }
                }
            }

            input {
                height: $size-mobile;
                min-height: $size-mobile;
                width: $input-width-mobile;
                min-width: $input-width-mobile;
                border: none;
                padding: 0;
                text-align: center;

                @include mobile {
                    font-size: 1.4rem;
                }

                @include after-mobile {
                    height: $size;
                    min-height: $size;
                    width: $input-width;
                    min-width: $input-width;
                }

                &:focus {
                    border: none;
                }
            }
        }
    }

    &_type {
        &_checkbox,
        &_radio {
            label {
                font-weight: $font-weight-regular;
                font-size: 1.2rem;
                line-height: 1.6rem;
                padding-bottom: 0;
                cursor: pointer;
                width: fit-content;
                min-width: unset;

                &:first-of-type {
                    display: inline-block;
                    max-width: calc(100vw - 2.4rem - 45px);
                    vertical-align: middle;

                    @include mobile {
                        max-width: calc(100vw - 2.8rem - 45px);
                    }

                    ~ label {
                        margin-left: 1.2rem;

                        @include mobile {
                            margin-left: 1.4rem;
                        }
                    }
                }

                &:last-of-type {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            &_checkbox {
                line-height: 1.6rem;
            }
        }
    }

    [type='checkbox'],
    [type='radio'] {
        position: absolute;
        opacity: 0;
        padding: 0;
        min-width: 0;
        min-height: 0;

        + .input-control {
            --box-color: #{white};

            border-radius: 2px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            padding: 0;
            width: 1.6rem;
            height: 1.6rem;
            background-color: var(--box-color);
            border: 1px solid var(--input-border-color);
            transition-property: background-color, border-color;
            transition-duration: .25s;
            transition-timing-function: ease-in;
            will-change: background-color, border-color;
            margin-right: 1rem;

            &::after {
                --checkmark-color: transparent;

                content: '';
                position: absolute;
                pointer-events: none;
                background: var(--checkmark-color);
                transition-duration: .25s;
                transition-timing-function: ease-in;
                transition-property: background-color;
                will-change: background-color;
            }

            &:hover {
                @include after-mobile {
                    border-color: var(--primary-base-color);
                }

                &::after {
                    @include after-mobile {
                        --checkmark-color: var(--secondary-dark-color);
                    }
                }
            }
        }

        &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
        }

        &:checked + label {
            background-color: var(--options-active-color);
            border-color: var(--options-active-color);

            &::after {
                --checkmark-color: #{$white};
            }
        }
    }

    [type='checkbox'] {
        + label {
            &::after {
                left: .25rem;
                top: .65rem;
                width: .24rem;
                height: .24rem;
                box-shadow:
                    2px 0 0 var(--checkmark-color),
                    4px 0 0 var(--checkmark-color),
                    4px -2px 0 var(--checkmark-color),
                    4px -4px 0 var(--checkmark-color),
                    4px -6px 0 var(--checkmark-color),
                    4px -8px 0 var(--checkmark-color);
                transform: rotate(45deg);
                transition-property: background-color, box-shadow;
                will-change: background-color, box-shadow;

                @include mobile {
                    left: .35rem;
                    top: .84rem;
                    width: .28rem;
                    height: .28rem;
                }
            }
        }

        + .input-control {
            border-radius: var(--input-checkbox-border-radius);
            float: left;
        }

        &:checked {
            &:hover {
                + label {
                    @include after-mobile {
                        background-color: var(--primary-base-color);
                    }
                }
            }

            + .input-control {
                background-color: var(--input-checkbox-checked-background-color);
                border-color: var(--input-checkbox-checked-border-color);

                &::after {
                    --checkmark-color: #{$white};

                    left: .2rem;
                    top: .64rem;
                    width: .24rem;
                    height: .24rem;
                    box-shadow:
                        2px 0 0 var(--checkmark-color),
                        4px 0 0 var(--checkmark-color),
                        4px -2px 0 var(--checkmark-color),
                        4px -4px 0 var(--checkmark-color),
                        4px -6px 0 var(--checkmark-color),
                        4px -8px 0 var(--checkmark-color);
                    transform: rotate(45deg);
                    transition-property: background-color, box-shadow;
                    will-change: background-color, box-shadow;
                }
            }
        }
    }

    [type='radio'] {
        + label {
            border-radius: 50%;

            &::after {
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: .6rem;
                height: .6rem;
                border-radius: 50%;
                transition-property: background-color, box-shadow;
                will-change: background-color, box-shadow;

                @include mobile {
                    width: .7rem;
                    height: .7rem;
                }
            }
        }

        &:focus + label {
            --box-color: var(--secondary-light-color);

            border-color: var(--primary-base-color);

            &::after {
                --checkmark-color: #{$white};
            }
        }
    }

    &-Labels {
        span {
            letter-spacing: 1.05px;
        }
    }

    .FieldInput {
        &-Password {
            @include mobile {
                position: relative;
            }

            input {
                --input-padding: 8px 1.5rem 9px;

                padding-right: 41px;
            }

            &_togglePasswordActive {
                .FieldInput-TogglePassword {
                    padding: 4px 12px 0;
                    line-height: 3.6rem;

                    --button-padding: 2px 12px 0;

                    @include hide-password-icon;

                    &::before {
                        color: var(--primary-dark-color);
                    }
                }
            }
        }

        &-TogglePassword {
            width: auto;
            position: absolute;
            right: 0;
            top: 0;
            margin-top: 0;
            padding: 0 13px;
            line-height: 4rem;

            --button-padding: 0 13px;
            --button-background: transparent;
            --button-line-height: normal;
            --button-border-width: 0;
            --button-hover-background: transparent;

            @include show-password-icon;

            span {
                display: none;
            }
        }
    }

    &_isDisabled {
        .Field {
            &-Label {
                color: var(--form-label-disable-color);
            }

            &Select {
                cursor: default;

                &[aria-expanded="true"] {
                    &:after {
                        transform: translateY(-2px) rotate(45deg);
                        bottom: 0;
                    }
                }
            }
        }
    }
}
