/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NotificationList {
    position: fixed;
    z-index: 500;
    top: calc(var(--header-height) + var(--breadcrumbs-height) + 20px);
    right: 10px;
    max-width: 550px;
    list-style: none;

    &_isShipingStep,
    &_isBillingStep {
        top: calc(var(--header-height) + 1.5em);
    }

    @include after-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    @include desktop-xxl {
        right: calc((100% - var(--content-wrapper-width))/2);
    }

    @include mobile {
        overflow: hidden;
        max-width: 100vw;
        width: 100%;
        top: var(--header-total-height);
        right: 0;
    }
}
