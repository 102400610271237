/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Yehan Virantha <yviranta@kemana.com>
 * http://www.kemana.com
 *
 */

.kemanapwa-home-page-spot-banner {
    padding-bottom: 40px;

    @include mobile {
        padding-bottom: 0;
        margin-top: 2rem;
    }

    .fixed-width {
        display: flex;
        justify-content: space-between;
        padding: 0;

        @include mobile {
            flex-direction: column;
            min-height: 110px;
        }
    }

    .widget {
        > h2 {
            @include sub-heading;
            color: $dark2;
            font-weight: $font-weight-extrabold;
            letter-spacing: .6px;

            @include mobile {
                font-size: 2rem;
                line-height: 2.2rem;
                margin-top: 4rem;
            }
        }
    }

    .banner {
        width: 100%;
        padding: 0 5px;

        @include mobile {
            padding-bottom: 15px;
        }

        @include desktop {
            &:first-child {
                padding-left: 0;
            }
            
            &:last-child {
                padding-right: 0;
            }
        }

        &:last-child {
            @include mobile {
                padding-bottom: 10px;
            }
        }
    }

    .banner-img {
        border-radius: 0;

        a {
            width: 100%;

            &:hover {
                @include desktop {
                    img {
                        opacity: 0.6;
                        transition: 0.5s;
                    }
                }
            }
        }
    }

    .banner-desc {
        text-align: center;

        @include mobile {
            padding-top: 5px;
        }

        p {
            font-size: 17px;
        }
    }
}
