/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --ProductSale-label-color: #{$white};
    --ProductSale-label-background-color: var(--secondary-error-color);
    --ProductSale-label-font-weight: #{$font-weight-bold};
}

.ProductSale {
    &-Label {
        position: absolute;
        height: 2.7rem;
        color: var(--ProductSale-label-color);
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: var(--ProductSale-label-font-weight);
        display: flex;
        align-items: center;
        padding: 2px 20px 2px 10px;
        border-radius: 3px 4px 21px 3px;
        background: var(--ProductSale-label-background-color);
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);

        @include mobile {
            font-size: 1rem;
            line-height: 1.9rem;
            height: 2.5rem;
        }

        &_displayPosition {
            &_top-left {
                left: -1rem;

                @include mobile {
                    left: 0;
                }
            }

            &_top-right {
                right: -1rem;

                @include mobile {
                    right: 0;
                }
            }

            &_bottom-left {
                left: -1rem;
                bottom: 0;

                @include mobile {
                    left: 0;
                }
            }

            &_bottom-center {
                bottom: 0;
            }

            &_bottom-right {
                right: -1rem;
                bottom: 0;

                @include mobile {
                    right: 0;
                }
            }
        }
    }
}

// PDP only styles
.ProductActions {
    .ProductSale {
        &-Label {
            width: fit-content;
            position: static;
            margin-bottom: 1rem;

            @include mobile {
                order: -4;
                margin-left: 1rem;
            }
        }
    }
}
