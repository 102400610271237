/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .Logo {
  --placeholder-image: none;
  --placeholder-animation: none;
  --image-default-background: transparent;

  height: 100%;
  display: flex;
  align-items: center;
  min-height: 35px;
}
