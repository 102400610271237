/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.InstallPrompt {
    background: $peach;
    // position: fixed;
    // top: 0;
    // right: 0;
    // left: 0;
    // z-index: 1000;


    div {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        margin: 0;
        align-items: center;
    }

    p {
        font-size: 10px;
        letter-spacing: .25px;
        color: $green3;
        font-weight: $font-weight-regular;
        line-height: 11px;
        text-align: left;
        padding: 0;

        .InstallPrompt-title {
            font-size: 12px;
            letter-spacing: .3px;
            color: $default-primary-light-color;
            font-weight: $font-weight-bold;
            line-height: 18px;
            display: block;
        }
    }

    .Button {
        background: $default-primary-light-color;
        color: $white;
        font-size: 12px;
        font-weight: $font-weight-bold;
        margin: 0;
        padding: 11px 25px;
        margin-left: auto;
    }


    @include before-desktop {
        + .CmsPage {
            margin-top: 0;

            .CmsPage-Wrapper {
                margin-top: 0;
            }
        }
    }
}
