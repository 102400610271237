/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height));
    --footer-content-height: 320px;
    --footer-copyright-height: 41px;
    --footer-top-section-background: #{$green3};
    --footer-top-section-max-width: 1135px;

    --footer-copyright-bg-color: #{$green3};
    --footer-copyright-color: #{$grey14};

    --footer-social-icon-width: 30px;
    --footer-social-icon-bg-hover: #{$white};
    --footer-social-icon-hover: #{$green3};
}

.Footer {
    $column-count: 4;

    @include mobile {
        margin-bottom: var(--navigation-tabs-height);
    }

    @include print-only {
        display: none;
    }

    &-CopyrightContentWrapper {
        background-color: var(--footer-copyright-bg-color);
    }

    &-CopyrightContent {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);
    }

    &-Copyright {
        color: var(--footer-copyright-color);
        text-align: center;
        font-size: 1.2rem;
        display: flex;
        align-items: center;

        a {
            color: inherit;
        }

        @include copyright-icon;

        &:before {
            padding-right: 1rem;
        }
    }

    &-Content {
        background-color: var(--footer-top-section-background);
        min-height: 348px;

        @include desktop {
            min-height: 195px;
        }

        .Section-Non-Accordion {
            &.Mobile-only {
                margin-bottom: 3rem;

                .SubTitle-Secondary,
                .Customer-Service {
                    text-align: center;
                }

                .SubTitle-Secondary {
                    &:after {
                        display: none;
                    }
                }

                .Customer-Service {
                    .SubTitle-Secondary {
                        padding-top: 1.5rem;
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }

    &-Columns {
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding: 30px 20px 20px;

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        @include after-mobile {
            padding: 39px 20px 40px;
            max-width: var(--footer-top-section-max-width);
        }

        @include tablet {
            padding: {
                left: 30px;
                right: 30px;
            };
        }
    }

    &-Column {
        width: 100%;

        @include desktop {
            width: calc(100% - 350px);
        }

        &:nth-child(2) {
            @include desktop {
                width: 100%;
                max-width: 350px;
            }
        }

        .SubTitle-Secondary {
            font-size: 1.6rem;
            line-height: 25px;
            margin-bottom: 15px;
            color: $white;

            @include mobile {
                margin-bottom: 0;
                position: relative;
            }

            &::after {
                color: $white;

                @include mobile {
                    top: 25px;
                }
            }
        }

        .Button_likeLink_Secondary {
            font-size: 1.2rem;
        }
    }

    &-ColumnTitle {
        text-transform: uppercase;
        margin: 0 0 15px;
        font-size: 2rem;
        display: none;

        @include after-mobile {
            margin: 0 0 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }

        a + a {
            margin-left: 0;
        }
    }

    &-ColumnItem {
        color: inherit;
        margin-bottom: 12px;
        font-size: 1.6rem;

        &:last-of-type {
            margin: 0;
        }

        &_type_image {
            width: 25px;

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-left: 100px;
            }
        }
    }

    &-FooterLeftContent {
        .CmsBlock-Wrapper {
            display: flex;
        }

        .Footer-Content {
            width: 100%;

            @include desktop {
                display: flex;
            }
        }

        .Section {
            width: 100%;
            text-align: left;

            &:last-child {
                border-bottom: 1px solid var(--primary-divider-color);
            }

            &:nth-child(4) {
                details {
                    summary {
                        border-top: 0;
                    }
                }
            }

            @include desktop {
                padding-right: 20px;
                max-width: 224px;

                &:first-child {
                    min-width: 330px
                }
            }

            &.Contact-Info {
                @include mobile {
                    margin-bottom: 3rem;
                }

                > .SubTitle-Secondary {
                    @include mobile {
                        margin-bottom: 1.5rem;
                    }
                }
            }

            &.Links-Container {
                .Button_likeLink {
                    line-height: 18px;
                    margin-bottom: 10px;
                    color: $green6;
                    font-weight: $font-weight-bold;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .Button_likeLink {
                width: 100%;
            }

            @include mobile {
                &.text-center {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        .Contact-Info {
            .SubTitle-Secondary {
                text-align: center;

                @include mobile {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .mobile-contact-info {
            text-align: center;
            padding-bottom: 30px;

            .SubTitle-Secondary {
                margin-bottom: 15px;
            }
        }

        .Customer-Service {
            margin-bottom: 20px;

            @include mobile {
                margin-bottom: 15px;
                text-align: center;
            }

            .SubTitle-Secondary {
                margin-bottom: 10px;
                color: $white;
                font-weight: $font-weight-regular;
                font-size: 1.4rem;
                line-height: 16px;
            }

            p {
                font-size: 1.2rem;
                color: $grey21;
                font-weight: $font-weight-light;
            }
        }

        .Customer-Email {
            position: relative;
            padding-left: 27px;

            @include footer-email-icon;

            &::before {
                @include mobile {
                    margin-right: 10px;
                }
            }

            @include mobile {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                padding: 0;
            }

            @include desktop {
                &:before {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 4px;
                }
            }

            .Button_likeLink_Primary {
                --button-like-link-primary-color: #{$green6};
                --button-like-link-primary-hover-color: #{$green6};

                letter-spacing: #{$letter-spacing-m};
                font-weight: $font-weight-bold;
                font-size: 1.2rem;
                margin-bottom: 0;

                @include mobile {
                    width: auto;
                }
            }
        }

        .Customer-Number {
            position: relative;
            padding-left: 27px;
            line-height: 20px;
            margin-bottom: 13px;
            color: $green6;
            font-weight: $font-weight-bold;
            font-size: 12px;

            @include telephone-icon;

            @include mobile {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                padding: 0;

                &:before {
                    margin-right: 10px;
                }
            }

            @include desktop {
                &:before {
                    display: block;
                    position: absolute;
                    left: 1px;
                    top: 3px;
                }
            }

            &-Title {
                font-weight: #{$font-weight-bold};
            }

            .Button_likeLink_Primary {
                --button-like-link-primary-color: #{$green6};
                --button-like-link-primary-hover-color: #{$green6};

                letter-spacing: #{$letter-spacing-m};
                font-weight: $font-weight-bold;
                font-size: 1.2rem;
                margin-bottom: 0;

                @include mobile {
                    width: auto;
                }
            }
        }
    }

    &-FooterRightContent {
        @include mobile {
            margin-top: 25px;
        }

        .SubTitle-Secondary {
            margin-bottom: 15px;

            @include mobile {
                margin-bottom: 12px;
            }
        }

        .Payment-Option {
            margin-bottom: 15px;

            @include mobile {
                padding: 0;
            }

            @include desktop {
                margin-bottom: 20px;
            }

            .Image {
                width: 53px;
                padding-bottom: 30px;
                margin-right: 10px;
                margin-bottom: 5px;

                @include desktop {
                    padding-bottom: 31px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .Social-Media {
            @include mobile {
                margin-bottom: 15px;
            }
            &-Icons {
                display: flex;

                @include mobile {
                    justify-content: center;
                }

                > a {
                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                .Facebook,
                .Twitter,
                .Instagram,
                .Youtube,
                .Pinterest,
                .Linkedin {
                    width: var(--footer-social-icon-width);
                    height: var(--footer-social-icon-width);
                    border-radius: 50%;
                    text-align: center;
                    margin-right: 1rem;
                    text-decoration: none;

                    &:hover {
                        @include desktop {
                            background-color: var(--footer-social-icon-bg-hover);
                        }

                        &:before {
                            @include desktop {
                                color: var(--footer-social-icon-hover);
                            }
                        }
                    }
                }

                .Facebook {
                    line-height: 35px;

                    @include facebook-icon;
                }

                .Twitter {
                    line-height: 34px;

                    @include twitter-icon;
                }

                .Instagram {
                    line-height: 34px;

                    @include instagram-icon;

                    &::before {
                        margin-left: 1px;
                    }
                }

                .Youtube {
                    line-height: 32px;

                    @include youtube-icon;
                }

                .Pinterest {
                    line-height: 35px;

                    @include pinterest-icon;
                }

                .Linkedin {
                    line-height: 33px;

                    @include linkedin-icon;
                }
            }
        }
    }

    &_isShipingStep,
    &_isBillingStep,
    &_isSuccessStep,
    &_isCallbackStep {
        .Footer-Content {
            display: none;
        }
    }
}
