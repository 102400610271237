.kemanapwa-home-page-shop-by-look-banner {
    @include desktop {
        margin-bottom: 4rem;
    }

  .ShopByLookHomeBanner {
    display: flex;
    background: $white;

    @include mobile {
        flex-direction: column;
        padding-bottom: 3rem;

        &-Container {
            order: -1;
        }
    }

    &-Container {
        flex: 1;

        .ShopByLookImage-Image {
            display: inherit;

            @include desktop {
                min-width: 502px;
                min-height: 293px;
            }

            @include desktop-xxl {
                min-width: 650px;
                min-height: 379px;
            }
        }
    }

    &-Title {
        letter-spacing: $letter-spacing-s;
        margin: 0 auto 12px;

        @include mobile {
            margin-top: 3rem;
        }
    }

    &-Text {
        margin: 0 auto 5rem;
        max-width: 18rem;

        @include mobile {
            margin-bottom: 4rem;
        }
    }

    &-RightContent {
        flex: 1;
    }

    &-LeftContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
  }
}
