/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
        margin-bottom: 0;
        margin-top: 0;
    }

    img {
        margin-bottom: 40px;
    }

    &-Button {
        margin-top: 40px;
        margin-bottom: 120px;

        @include mobile {
            margin-top: 2.8rem;
        }
    }

    &-Text {
        font-size: 14px;
        font-weight: $font-weight-regular;
        color: $black;
    }

    &-Subtitle {
        font-size: 24px;
        font-weight: $font-weight-extrabold;
        letter-spacing: .6px;
        line-height: 27px;
        margin: 0 0 2rem;
        color: $dark2;

        @include mobile {
            font-size: 20px;
            line-height: 22px;
        }
    }

    &-Wrapper {
        text-align: center;
    }
}

main {
    &.NoMatch {
        @include desktop {
            padding-top: 9rem;
        }
    }
}
