/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: inherit;
    outline: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    // backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    @include mobile {
        position: unset;
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

header,
footer,
main {
    font-size: 1.4rem;
    letter-spacing: #{$letter-spacing-m};
}

main {
    min-height: calc(100vh - var(--header-height) - var(--footer-height) - var(--breadcrumbs-height));
    min-height: 306px;
    max-width: 100vw;

    @include mobile {
        min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
        margin-top: var(--header-total-height);
        margin-bottom: calc(var(--navigation-tabs-height) + 10px);
    }

    @include desktop {
        min-height: calc(100vh - 489px);
    }

    @include print-only {
        width: 100% !important;
        min-height: auto !important;
        margin-top: 0;
        display: none;
    }

    &.CategoryPage {
        @include mobile {
            margin-bottom: var(--navigation-tabs-height);
        }
    }

    &.Checkout {
        @include desktop {
            min-height: calc(100vh - 116px);
        }

        @include mobile {
            margin-bottom: 0;
        }
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-bottom: 1.44rem;

    @include mobile {
        margin-bottom: 1.68rem;
    }
}

img {
    width: 100%;
}

html {
    font-size: $font-standard-size;
}

body {
    font-family: $base-font-family;
    font-weight: $font-weight-regular;
    color: var(--primary-dark-color);
    scroll-behavior: smooth;
    background-color: var(--body-background-color);

    @include print-only {
        background-color: transparent;
        margin-top: 0 !important;
    }
}

a {
    color: $black;
    text-decoration: inherit;
    transition-timing-function: ease-out;
    transition-duration: .25s;

    &:hover {
        cursor: pointer;
    }
}

li,
dl,
button {
    font-family: $base-font-family;
    font-size: 1.4rem;
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-bottom: 6px;

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    // &:last-child { commented to fix ProductCard last item margin issue
    //     margin-bottom: 20px;
    // }
}

select {
    border-radius: 0;
    appearance: none;
}

button {
    cursor: pointer;

    @include mobile {
        user-select: none;
    }
}

textarea {
    resize: none;

    @include mobile {
        font-size: 1.6rem;
    }
}

mark {
    margin-right: 5px;
}

iframe {
    max-width: 100%;

    @include print-only {
        display: none !important;
        height: 0 !important;
        visibility: hidden !important;
    }

    &[data-product="web_widget"] {
        + div {
            @include print-only {
                display: none !important;
                height: 0 !important;
                visibility: hidden !important;
            }
        }
    }
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}

input {
    border-radius: 0;
}
