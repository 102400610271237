/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductListWidget {
    padding-bottom: 30px;

    @include after-mobile {
        grid-column: 2;
        padding-bottom: 50px;
        min-height: auto;
    }

    &-Page {
        display: grid;
        grid-auto-rows: max-content;
        grid-gap: 20px;

        @include mobile {
            grid-gap: 0 10px;
            grid-template-columns: repeat(2, 1fr);
            border: 0;
        }

        @include tablet {
            grid-template-columns: repeat(4, 1fr);
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include desktop-xl {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    &-Carousel {
        width: 100%;

        @include mobile {
            padding-left: 5px;
            padding-right: 0;
        }

        .ProductCard {
            &-ProductActions {
                right: 15px;

                @include desktop {
                    right: 20px;
                }
            }
        }

        .react-multi-carousel-list {
            @include desktop {
                padding-bottom: 30px;
            }

            @include desktop-xxl {
                margin: 0 -10px;
                width: calc(100% + 20px);
            }

            .react-multi-carousel-track {
                .react-multi-carousel-item {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-bottom: 0;

                    &::before {
                        display: none;
                    }

                    @include mobile {
                        padding: 0 5px;
                    }

                    @include desktop {
                        padding: 0 10px;
                    }

                    .ProductCard {
                        width: 100%;
                    }
                }
            }

            .react-multi-carousel-dot-list {
                grid-gap: 0;

                @include mobile {
                    display: none;
                }

                .react-multi-carousel-dot {
                    display: flex;
                    margin-bottom: 0;
                    padding: 0;

                    &::before {
                        display: none;
                    }

                    button {
                        border: 1px solid $grey14;
                        background-color: $grey14;
                        width: 10px;
                        height: 10px;
                        margin-right: 5px;

                        &:hover {
                            @include desktop {
                                border-color: $green4;
                                background-color: $green4;
                            }
                        }
                    }

                    &--active {
                        button {
                            background-color: $green4;
                            border-color: $green4;
                        }
                    }
                }
            }
        }

        .ProductListPage-Slider {
            &Arrows {
                width: 100%;
                position: absolute;
                top: 44%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);

                @include mobile {
                    display: none;
                }

                span {
                    display: none
                }
            }

            &Buttons {
                background: $white;
                box-shadow: $box-shadow6;
                min-width: 40px;
                min-height: 40px;
                border-radius: 50%;
                padding: 0;
                position: absolute;
                cursor: pointer;
            }

            &Button {
                &Prev {
                    @include slider-left-icon;

                    &::after {
                        line-height: 4rem;
                    }

                    @include desktop {
                        left: 0;
                    }

                    @media (min-width: 1380px) {
                        left: -20px;
                    }

                    &_isHidden {
                        display: none;
                    }

                    &_isDisabled {
                        display: none;

                        &::after {
                            color: $grey14;
                        }
                    }
                }

                &Next {
                    @include slider-right-icon;

                    &::after {
                        line-height: 4rem;
                    }

                    @include desktop {
                        right: 0;
                    }

                    @media (min-width: 1380px) {
                        right: -20px;
                    }

                    &_isHidden {
                        display: none;
                    }

                    &_isDisabled {
                        display: none;

                        &::after {
                            color: $grey14;
                        }
                    }
                }
            }
        }
    }

    .ProductCard {
        &:nth-child(3),
        &:nth-child(4) {
            @include tablet {
                margin-top: 0;
            }
        }

        &:nth-child(5),
        &:nth-child(6) {
            @include tablet {
                display: none;
            }

            @include desktop {
                display: none;
            }

            @include desktop-xl {
                display: flex;
            }
        }
    }
}
