/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.PayInStoreDisclaimerPopup {
    @include mobile {
        align-items: center;
        z-index: 9999;
        height: 100vh;
    }

    .Popup {
        &-Header {
            display: none;
        }

        &-CloseBtn {
            @include mobile {
                top: -10px;
                right: -10px;
                display: flex;
            }
        }

        &-Container {
            @include mobile {
                width: 90%;
                position: relative;
            }
        }

        &-Content {
            border-radius: 5px; 
        }
    }

    &-Title {
        margin-bottom: 20px;
    }

    &-Message {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-bottom: 30px;
    }
}
