/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.ARLink {
    &-Container{
      width: 100%;
      order: 25;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 1.5rem;

      @include mobile {
        margin-top: 0;
      }

      &:hover,
      &:focus {
        text-decoration: none;

        .ARLink-ImageContainer {
          &::before {
            @include desktop {
              color: var(--primary-light-color);
            }
          }
        }

        .ARLink-ImageContainer,
        .ARLink-LinkText {
          @include desktop {
            color: var(--primary-light-color);
          }
        }
      }
    }

    &-ImageContainer {
      @include ar-cube-icon;
      display: flex;
      margin-right: .5rem;
    }
}
