/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

 @keyframes animationSlider {
    from {
        opacity: 0;
        transform: translateX(-300px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes animationSliderHide {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-200px);
    }
}

@mixin animation-left-hide {
    animation: animationSliderHide 0.5s ease-in forwards;
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

@mixin animation-right-show {
    animation: animationSlider 0.5s ease-out forwards;
    opacity: 1;
    visibility: visible;
    position: static;
}

@mixin fixed-width {
    width: 100%;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;

    @include desktop {
        max-width: $layout__max-width;
        padding: 0 10px;
    }
}

@mixin animation {
    transition-timing-function: ease-out;
    transition-duration: .25s;
}

@mixin iconmoon($iconmoon-fontsize, $iconmoon-color) {
    font-family: $icomoon-font-family;
    font-size: $iconmoon-fontsize;
    line-height: $iconmoon-fontsize;
    color: $iconmoon-color;
}

@mixin animation {
    transition-timing-function: ease-out;
    transition-duration: .25s;
}

@mixin h1 {
    margin-bottom: 30px;
    color: $h1-font-color;
    font-size: $h1-font-size-desktop;
    line-height: $h1-font-line-height-desktop;
    font-weight: $h1-font-weight;
    letter-spacing: $h1-letter-spacing;

    @include mobile {
        font-size: $h1-font-size-mobile;
        line-height: $h1-font-line-height-mobile;
        margin-bottom: 20px;
    }
}


@mixin h2 {
    margin-bottom: 30px;
    color: $h2-font-color;
    font-size: $h2-font-size-desktop;
    line-height: $h2-font-line-height-desktop;
    font-weight: $h2-font-weight;
    letter-spacing: $h2-letter-spacing;

    @include mobile {
        font-size: $h2-font-size-mobile;
        line-height: $h2-font-line-height-mobile;
        margin-bottom: 20px;
    }
}

@mixin h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: $h3-font-color;
    font-size: $h3-font-size-desktop;
    line-height: $h3-font-line-height-desktop;
    font-weight: $h3-font-weight;
    letter-spacing: $h3-letter-spacing;

    @include mobile {
        font-size: $h3-font-size-mobile;
        line-height: $h3-font-line-height-mobile;
    }
}

@mixin h4 {
    margin-bottom: 30px;
    color: $h4-font-color;
    font-size: $h4-font-size-desktop;
    line-height: $h4-font-line-height-desktop;
    font-weight: $h4-font-weight;
    letter-spacing: $h4-letter-spacing;

    @include mobile {
        font-size: $h4-font-size-mobile;
        line-height: $h4-font-line-height-mobile;
        margin-bottom: 20px;
        letter-spacing: .5px;
    }
}

@mixin h5 {
    margin-bottom: 30px;
    color: $h5-font-color;
    font-size: $h5-font-size-desktop;
    line-height: $h5-font-line-height-desktop;
    font-weight: $h5-font-weight;
    letter-spacing: $h5-letter-spacing;
}

@mixin h6 {
    margin-bottom: 30px;
    color: $h6-font-color;
    font-size: $h6-font-size-desktop;
    line-height: $h6-font-line-height-desktop;
    font-weight: $h6-font-weight;
    letter-spacing: $h6-letter-spacing;

    @include mobile {
        font-size: $h6-font-size-mobile;
        line-height: $h6-font-line-height-mobile;
        margin-bottom: 20px;
    }
}

@mixin sub-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: $h4-font-color;
    font-size: $h3-font-size-desktop;
    line-height: $h4-font-line-height-desktop;
    font-weight: $h4-font-weight;
    letter-spacing: $h4-letter-spacing;

    @include mobile {
        font-size: $h4-font-size-mobile;
        line-height: $h4-font-line-height-mobile;
        margin-bottom: 20px;
    }
}

@mixin sub-heading-1 {
    color: $subtitle-primary-font-color;
    letter-spacing: $subtitle-primary-letter-spacing;
    font-weight: $subtitle-primary-font-weight;
    font-size: $subtitle-primary-font-size;
    line-height: $subtitle-primary-font-line-height;
    margin: $subtitle-primary-margin;
}

@mixin sub-heading-2 {
    color: $subtitle-secondary-font-color;
    letter-spacing: $subtitle-secondary-letter-spacing;
    font-weight: $subtitle-secondary-font-weight;
    font-size: $subtitle-secondary-font-size;
    line-height: $subtitle-secondary-font-line-height;
    margin: $subtitle-secondary-margin;
}

@mixin paragraph-text {
    font-family: $paragraph-font-family;
    font-weight: $paragraph-font-weight;
    color: $paragraph-font-color;
    letter-spacing: $paragraph-letter-spacing;
    font-size: $paragraph-font-size-desktop;
    line-height: $paragraph-font-line-height-desktop;
}

@mixin icon($content, $position: before, $size: 2rem, $color: $default-primary-dark-color) {
    @if $position == before {
        &:before {
            font-family: $icomoon-font-family;
            content: $content;
            font-size: $size;
            line-height: $size;
            color: $color;
            font-weight: 400;
            transition: all .2s;
        }
    }
    @else {
        &:after {
            font-family: $icomoon-font-family;
            content: $content;
            font-size: $size;
            line-height: $size;
            color: $color;
            font-weight: 400;
            transition: all .2s;
        }
    }
}

@mixin custom-scroll {
    /* width */
    &::-webkit-scrollbar {
        width: 20px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #{$grey15};
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #{$grey23};
        border-radius: 25px;
        border: 6px solid #{$grey15};
    }

}

@mixin custom-radio-button {
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 1px solid #{$grey7};
        transition-timing-function: ease-out;
        transition-duration: .25s;
    }

    &_isSelected {
        &::after {
            border: 5px solid var(--primary-base-color);
            width: 5px;
            height: 5px;
            top: -1px;
        }
    }

    &:not(&_isSelected):hover {
        &::after {
            border: 1px solid var(--primary-dark-color);
            width: 13px;
            height: 13px;
        }
    }
}

@mixin cms-content-wrapper {
    max-width: 1080px;
    border: 1px solid $grey14;
    background: #{$white};
    margin-top: 30px;
    margin-bottom: 47px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 2px;

    @include mobile {
        padding-left: 10px;
        padding-right: 10px;
        border: 0;
    }
}

@mixin cms-content-title {
    font-size: 30px;
    font-weight: $font-weight-bold;
    line-height: 47px;
    letter-spacing: .75px;
}

// Touch devices only
@mixin touchdevice {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}
