/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --newsletter-subscription-input-background: var(--input-background-color);
    --newsletter-subscription-input-color: var(--primary-dark-color);
    --newsletter-subscription-button-background: var(--link-color);
}

.NewsletterSubscription {
    margin-bottom: 30px;

    @include desktop {
        margin-bottom: 40px;
    }

    .FieldForm {
        &-Fieldset {
            display: flex;
            flex-wrap: nowrap;

            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }

            .Field {
                width: 100%;
                margin-top: 0;

                &-Message {
                    position: absolute;
                }

                input {
                    background-color: var(--newsletter-subscription-input-background);
                    color: var(--newsletter-subscription-input-color);
                    padding: 12px 12px;
                    border-right: 0px;
                    border-radius: 5px 0 0 5px;
                }

                &_hasError {
                    input {
                        border-right: 0;
                    }
                }
            }
        }

        &-Fields {
            width: calc(100% - 131px);
            margin-bottom: 0;
        }

        &-Button {
            border-radius: 0 5px 5px 0;
            padding: 13px 20px 11px;
            width: 131px;
        }
    }

    .Form {
        &_isInvalid {
            margin-bottom: 1.8rem;
        }
    }
}
