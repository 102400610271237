/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.kemanapwa-best-selling-products,
.kemanapwa-home-page-new-arrivals,
.kemanapwa-home-page-all-day-exclusives,
.kemanapwa-home-page-top-categories {
    @include mobile {
        padding: 0;
    }

    h2 {
        @include sub-heading;
        color: $dark2;
        font-weight: $font-weight-extrabold;
        letter-spacing: .6px;

        @include mobile {
            font-size: 2rem;
            line-height: 2.2rem;
            margin-top: 4rem;
        }
    }

    .ProductListPage {
        @include mobile {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
