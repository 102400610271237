/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@keyframes FadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

:root {
    --menu-desktop-height: 4rem;
    --menu-background-mobile: #{$white};
    --menu-background-desktop: #{$orange2};
    --menu-parent-item-color: #{$white};
    --menu-parent-item-padding: 0 2.5rem;
    --menu-first-child-item-color: #{$dark2};
    --menu-child-item-color: #{$grey4};
    --menu-child-item-hover-color: var(--menu-first-child-item-color);
    --menu-mobile-border-bottom: #{$grey9};
    --menu-mobile-item-height: auto;
    --menu-item-height: 35px;
}

%menu-items-common {
    @include mobile {
        position: relative;
    }

    @include before-desktop-l {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.Menu {
    &-Link,
    &-SubCatLink {
        display: block;
        width: 100%;
        cursor: pointer;

        @include desktop {
            --link-color: #{$white};

            transition: none;
        }
    }

    &-SubCategories {
        padding-left: 0;
    }

    &-ItemList {
        display: block;

        @include desktop-l {
            display: flex;
            justify-content: space-between;
            width: 100%;

           > a {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        &_type_subcategory {
            @include before-desktop-l {
                padding: 0 2rem;
                height: calc(100vh - 110px);
                padding-bottom: 113px;
                overflow-y: auto;
                width: 100%;

                > div {
                    position: relative;

                    &:last-child {
                        .Menu-ItemFigure {
                            border-bottom: 0;
                        }
                    }
                }
            }

            @include desktop {
                justify-content: unset;
                flex-direction: column;
            }
        }
    }

    &-Item {
        padding: 0;
        @extend %menu-items-common;

        &Caption_type {
            &_main {
                font-size: 1.5rem;
                font-weight: bold;
                color: var(--menu-first-child-item-color);

                @include desktop-l {
                    color: var(--menu-parent-item-color);
                    font-size: 1.3rem;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                @include desktop-xl {
                    font-size: 1.4rem;
                    letter-spacing: .49px;
                    line-height: 22px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &Figure_type_main {
            text-transform: none;
            background-color: transparent;

            @include desktop {
                z-index: -1;
            }

            @include before-desktop-l {
                display: block;
                grid-template-columns: auto;
                padding-left: 0;
                height: auto;
                padding: 1.4rem 0;
            }
        }

        &:last-child {
            padding-right: 0;

            @include desktop {
                display: none;
            }

            .Menu-Link {
                display: none;

                &_isSignedIn {
                    display: inline-block;
                }
            }
        }

        &List {
            &_type_main {
                width: unset;
            }
        }

        &Figure {
            @include before-desktop-l {
                border-bottom: 1px solid var(--menu-mobile-border-bottom);

                .Menu-ItemCaption {
                    font-size: 1.4rem;
                    color: var(--menu-first-child-item-color);
                    font-weight: $font-weight-bold;

                    &_type_subcategory {
                        font-weight: $font-weight-regular;
                        color: $darkblack;
                        padding: 0;
                    }
                }
            }
        }

        .Menu-SubMenuMobileButton {
            @include menu-right-icon;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 25px;
            height: 50px;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: flex-end;

            &:after {
                padding-right: 0.4rem;
            }

            > span {
                display: none;
            }
        }
    }

    &-Link {
        @include before-desktop {
            padding: 0;
        }

        .Menu {
            &-ItemFigure {
                &:after {
                    display: none;
                }
            }
        }

        &:hover {
            @include desktop {
                --menu-item-hover-color: #{$white};
                text-decoration: underline;
            }
        }

        .Menu-ItemCaption_type_main {
            &:hover {
                @include desktop {
                    color: $white;
                }
            }
        }
    }

    &-Overlay{
        @include desktop {
            display: none;
        }
    }

    &-SubCatLink {
        @include mobile {
            .Menu-SubMenu {
                .Menu-ItemCaption {
                    font-weight: $font-weight-regular;
                }
            }
        }

        .Menu-ItemFigure {
            @include mobile {
                @include menu-next-icon;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: auto;
                padding: 1.4rem 0;
                white-space: normal;

                &:after {
                    padding-right: .4rem;
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        top: var(--menu-desktop-height);

        @include desktop {
            width: auto;
        }
    }

    &-SubCategoriesWrapperInner {
        @include desktop {
            min-width: 230px;
            background-color: transparent;
            opacity: 0;
            box-shadow: $box-shadow8;
            clip-path: none; 
         }

        &_isVisible {
            @include desktop {
                animation: FadeIn .1s linear 1 forwards;
                animation-delay: 400ms;
            }
        }
    }

    &-SubItemWrapper {
        @include desktop-l {
            margin-right: 0;
            margin-bottom: 0;
            flex-basis: auto;
        }

        &:not(:last-of-type) {
            @include desktop {
                border-bottom: 1px solid $green5;
            }
        }

        > .Menu-Link {
            @include desktop {
                text-transform: none;

                &:hover {
                    color: $white;
                    background: var(--header-icon-hover-color);
                    text-decoration: none;

                    .Menu-ItemCaption {
                        color: $white;
                    }
                }
            }
        }
    }

    &-SubLevelDesktop {
        @include desktop {
            display: none;
        }

        .Menu {
            &-ItemList {
                color: $darkblack;
                font-size: 14px;
                letter-spacing: .35px;
                line-height: 16px;
            }

            &-Link {
                &:hover {
                    color: $dark2;
                    text-decoration: none;
                }
            }
        }
    }

    &-SubItemWrapper {
        > .Menu-Link {
            padding: 0;
            @extend %menu-items-common;

            @include desktop {
                padding: 8px 10px;
            }

            .Menu-ItemCaption {
                @include before-desktop-l {
                    line-height: var(--menu-mobile-item-height);
                    font-weight: $font-weight-regular;
                    color: $darkblack;
                }

                @include desktop-l {
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: .49px;
                    color: var(--menu-first-child-item-color);
                }
            }
        }

        &:last-child {
            @include mobile {
                .Menu-ItemFigure {
                    border-bottom: 0;
                }
            }
        }
    }

    &-Main {
        &Categories {
            z-index: 1;

            @include mobile {
                opacity: 0;
                visibility: hidden;
            }

            @include desktop {
                padding: 0 10px;
            }

            .Menu-Link {
                &_isHovered {
                    @include desktop {
                        text-decoration: none;

                        .Menu-ItemCaption {
                            &::after {
                                content: '';
                                width: 100%;
                                background-color: $white;
                                height: 2px;
                                display: flex;
                                position: absolute;
                            }
                        }
                    }
                }
            }
        }
    }

    &-MenuWrapper {
        margin: 0;
        min-height: var(--menu-desktop-height);
        background-color: var(--menu-background-mobile);

        @include before-desktop-l {
            padding-top: 0;
            padding-bottom: 0;
            padding: 0 2rem;
        }

        @include desktop-l {
            background-color: var(--menu-background-desktop);
        }

        @include print-only {
            display: none;
        }

        @include mobile {
            @include animation-left-hide;

            opacity: 1;
            visibility: visible;
            z-index: 2;
            pointer-events: none;
            max-width: 40%;
        }
    }

    &-SubMenu {
        --overlay-background: #{$white};

        @include before-desktop-l {
            height: 100%;
            left: 0;
            padding-top: 55px;
            padding-bottom: 55px;
            position: fixed;
            top: 0;
            width: 100%;
            background: $white;
            overflow-y: hidden;
        }
    }

    &-SubCategories {
        background-color: var(--overlay-background);

        @include mobile {
            padding: 40px 55px 60px 1.4rem;
        }

        @include desktop {
            padding: 0;
        }
    }

    &Item-CompareLink {
        padding: 2rem;
        color: var(--primary-base-color);
        display: block;
        font-weight: $font-weight-bold;
        font-size: 1.2rem;
        border-bottom: 1px solid var(--menu-mobile-border-bottom);
        display: none;
    }
}

main {
    &.MenuPage {
        @include before-desktop-l {
            margin-top: 55px;
        }

        &:before {
            background: $white;
        }
    }
}
