/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --category-pagination-border: 1px solid #{$grey13};
    --category-pagination-active-border: 1px solid #{$grey14};
    --category-pagination-active-background: #{$grey15};
    --category-pagination-background: #{$white};
    --category-pagination-hover-border: 1px solid #{$green2};
    --category-pagination-width: 4rem;
    --category-pagination-height: var(--category-pagination-width);

    --color-pagination-link-text: #{$default-primary-dark-color};
    --color-pagination-link-active-text: var(--primary-base-color);
}

.CategoryPaginationLink {
    min-width: var(--category-pagination-width);
    height: var(--category-pagination-height);
    line-height: var(--category-pagination-height);
    display: block;
    text-align: center;
    background: var(--category-pagination-background);
    border: var(--category-pagination-border);
    color: var(--color-pagination-link-text);
    margin: 0 5px;
    border-radius: 0;
    font-size: 1.2rem;

    @include desktop {
        &:hover,
        &:focus {
            // --category-pagination-background: var(--category-pagination-hover-background);
            --category-pagination-border: var(--category-pagination-hover-border);

            text-decoration: none;
        }
    }

    &_isCurrent {
        --color-pagination-link-text: var(--color-pagination-link-active-text);
        --category-pagination-border: var(--category-pagination-active-border);
        --category-pagination-background: var(--category-pagination-active-background);
        --color-pagination-link-text: #{$grey12};

        @include desktop {
            &:hover,
            &:focus {
                --category-pagination-border: var(--category-pagination-active-border);
                cursor: auto;
            }
        }
    }
}

.CategoryPagination-Icon {
    @include prev-icon;

    &_isNext {
        @include next-icon;
    }
}
