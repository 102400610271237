/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-rating-background: #{$grey9};
    --product-rating-filled-background: #{$yellow3};
    --product-rating-size: 14px;
}

.ProductReviewRating {
    --percentage: 0%;

    display: inline-block;
    font-size: var(--product-rating-size);
    letter-spacing: 2px;

    &::before {
        content: '\e939 \e939 \e939 \e939 \e939';
        font-family: 'icomoon-font-family';
        letter-spacing: 1px;
        background:
            linear-gradient(
                90deg,
                var(--product-rating-filled-background) var(--percentage),
                var(--product-rating-background) var(--percentage)
            );

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &_isLoading {
        &::before {
            background-image: var(--placeholder-image);
            background-size: var(--placeholder-size);
            animation: var(--placeholder-animation);
        }
    }
}
