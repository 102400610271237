/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
$image-width: 70px;
$image-height: 70px;
$product-name-width: 135px;
$padding-around: 5px;

.react-tiny-popover-container {
  & > div{
    padding-right: 4px;
    padding-left: 4px;

    > div:first-child {
        z-index: 1;

        @include desktop {
          border-right-width: 10px !important;
          border-left-width: 10px !important;
          border-bottom-width: 10px !important;
        }
    }
  }
}

.ShopByLookProductPopup {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;

  &-HoverTag {
    box-shadow: 2px 1px 10px -2px rgba(0,0,0,0.75);
    background: rgba(255,255,255,0.5);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    position: relative;

    @include mobile {
      height: 21px;
      width: 21px;
    }
  }

  &-Popup {
    font-size: 10px;
    min-height: 70px;
    width: 250px;
    background: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    margin: -1px 0 0;

    @include desktop {
      margin-top: 3px;
    }

    .Loader{
      background: none;
    }
  }

  &-ProductContainer {
    display: flex;
    text-align: left;
    padding: 10px;

    @include mobile {
      padding: 5px;
    }
  }

  &-ProductImage {
    width: $image-width;
    height: $image-height;
  }

  &-ProductRightContent {
    width: calc(100% - 70px);
    padding-left: 10px;
  }

  &-ProductName {
    color: $black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 9px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    letter-spacing: $letter-spacing-m2;
  }

  &-ProductPrice {
    font-weight: $font-weight-bold;
    letter-spacing: 0.49px;
    font-size: 1.4rem;
    line-height: 22px;
    margin-bottom: 5px;
  }

  &-ShopLink {
    font-weight: $font-weight-bold;
    color: var(--primary-base-color);
    font-size: 1.2rem;
  }

  &-PopupMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1.4rem;
    padding: 20px;
  }

  &-Icon {
    cursor: pointer;

    &_isPopupVisible {
      .Image {
          background: $default-primary-light-color;
          border-radius: 100%;

          @include mobile {
            line-height: 20px;
          }

          @include desktop {
            line-height: 40px;
          }

          @include close-button;

          &:before {
              color: $white;
              position: relative;
              font-size: 9px;

              @include mobile {
                font-weight: $font-weight-bold;
                line-height: 21px;
              }

              @include desktop {
                font-size: 15px;
                line-height: 40px;
              }
          }

          .Image-Content {
              display: none;
          }
      }
  }

    .Image-Image {
        top: 2px;
        left: 1px;
    }
  }
}
