/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.kemanapwa-home-page-top-categories,
.kemanapwa-home-page-banner-categories {
    margin-bottom: 20px;
    min-height: 1096px;

    @media (min-width: 360px) {
        min-height: 1172px;
    }

    @media (min-width: 600px) {
        min-height: 1684px;
    }

    @media (min-width: 811px) {
        min-height: 650px;
    }

    @include desktop {
        margin-bottom: 30px;
        min-height: 445px;
    }

    @include desktop-xl {
        min-height: 475px;
    }

    @include desktop-xxl {
        min-height: 491px;
    }

     h2 {
         @include sub-heading;
     }

     .Home-Categories {
        display: flex;
        flex-wrap: wrap;

        @include mobile {
           flex-wrap: wrap;
        }

        @include desktop {
            margin-left: -10px;
            margin-right: -10px;
        }

         &-Item {
            width: 50%;
            margin-bottom: 10px;
            padding: 0 5px;
            display: flex;

            @include desktop {
                width: (100%/6);
                margin-bottom: 20px;
                padding: 0 10px;
            }

            @include tablet {
                width: (100%/4);
            }

            &-Container {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: $white;
                border-radius: 5px;
                transition: $transition1;
                text-align: center;
                margin: auto;
                box-shadow: $box-shadow1;

                @include desktop {
                    max-width: 200px;
                }

                &:hover {
                    @include desktop-l {
                        box-shadow: $box-shadow2;
                    }
                }
            }

            &-Left {
                width: calc(100% - 60px);
                padding: 10px 0 10px 10px;

                @include desktop-l {
                    width: calc(100% - 100px);
                    padding: 20px 0 15px 15px;
                }

                .SubTitle-Primary {
                    margin-bottom: 10px;

                    @include mobile {
                        font-size: 1.4rem;
                        margin-bottom: 0;
                        line-height: 1.9rem;
                    }
                }

                p {
                    @include mobile {
                        display: none;
                    }
                }
            }

            &-Right {
                width: 60px;
                height: 60px;

                @include desktop-l {
                    width: 100px;
                    height: 100px;
                }
            }
         }

        &-image {
            .Image {
                padding: 0;
                overflow: visible;
                height: auto;
                
                img {
                    object-fit: cover;
                    position: static;
                }
            }
        }

        &-content {
             width: 100%;

            .SubTitle-Primary {
                margin-bottom: 0;
                text-decoration: none;
                color: $black;
            }

            a {
                text-decoration: none;
                padding: 15px;
                float: left;
                width: 100%;
                color: $black;
                font-size: 1.4rem;
            }
        }
    }
}

.kemanapwa-home-page-banner-categories {
    min-height: 450px;

    @media (min-width: 360px) {
        min-height: 450px;
    }

    @media (min-width: 600px) {
        min-height: 550px;
    }

    @media (min-width: 811px) {
        min-height: 550px;
    }

    @include desktop {
        margin-bottom: 0;
        min-height: 257px;
    }

    @include desktop-xl {
        min-height: 257px;
    }

    @include desktop-xxl {
        min-height: 297px;
    }

    .Home-Categories {
        &-Item {
            margin-bottom: 0;

            @include mobile {
                margin-bottom: 20px;
            }

            @include tablet {
                width: (100%/2);
            }

            @include desktop {
                width: (100%/4);
            }

            &:nth-child(3),
            &:nth-child(4) {
                @include mobile {
                    margin-bottom: 0;
                }
            }

            &-Container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                background: $white;
                border-radius: 5px;
                box-shadow: $box-shadow1;
                margin: 0;

                @include desktop {
                    max-width: none;
                }
            }
        }

        &-image {
            .Image {
                display: flex;
            }
        }

        &-content {
            box-shadow: none;
            border-radius: 0;

            a,
            p {
                font-size: 1.6rem;
                line-height: 2rem;
            }  

            p {
                font-weight: $font-weight-regular;
                color: $black;
            }
        }
    }
}
