.kemanapwa-home-page-recently-viewed {
    background: $white;
    margin-bottom: 50px;

    .RecentlyViewedWidget {
        h3 {
            @include sub-heading;
            font-size: 24px;
            line-height: 27px;
            letter-spacing: 0.6px;
            color: $dark2;
            padding-top: 30px;
            font-weight: $font-weight-black;

            @include mobile {
                margin-bottom: 2rem;
                font-size: 2rem;
                line-height: 2.2rem;
                font-weight: $font-weight-black;
            }
        }

        .ProductCard-Link {
            border-radius: 2px;
            border: 1px solid $grey14;

            &:hover {
                @include desktop {
                    border: 1px solid $green2;
                }
            }
        }
    }
}