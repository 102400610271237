/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Yehan Virantha <yviranta@kemana.com>
 * http://www.kemana.com
 *
 */
 :root {
    --unit-sale-points-item-width: 250px;
}

.kemanapwa-home-page-usp {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background: $white;
    padding: 25px;
    min-height: 300px;

     @include mobile {
        padding: 15px 5px 10px;
     }

     @include tablet {
         min-height: 255px;
     }

     @include desktop {
         min-height: 128px;
     }

     @include desktop-xl {
         min-height: 110px;
     }

     .widget.block {
         width: 100%;
     }

    .unit-sale-points {
        max-width: 100%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include mobile {
            flex-wrap: wrap;
        }
    }

    .sale-point {
        display: flex;
        align-items: center;
        margin: 0 25px;
        width: var(--unit-sale-points-item-width);

        @include mobile {
            width: 50%;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            padding: 0 5px 10px;

        }
    }

    .image-container {
        width: 62px;
        height: 60px;
        display: flex;
        justify-content: center;
    }

    .content-container {
        padding-left: 15px;
        width: calc(100% - 50px);

        @include mobile {
            width: 100%;
            text-align: center;
            padding: 5px 0 0;
        }
    }

    .point-title {
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing-m;
        line-height: 1.4rem;
        color: $default-primary-dark-color;
        margin-bottom: 10px;
        font-size: 12px;

        @include mobile {
            font-size: 1.1rem;
            line-height: 1.5rem;
        }
    }

    .point-content {
        letter-spacing: $letter-spacing-m;
        line-height: 1.8rem;
        color: $default-primary-dark-color;
        font-size: 1.2rem;
        font-weight: $font-weight-light;

        @include mobile {
            font-size: 1.1rem;
            line-height: 1.5rem;
        }
    }
}