/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --homepage-slider-color: #{$white};
    --homepage-slider-slider-crumb-bg-color: #{$grey14};
    --homepage-slider-slider-crumb-active-bg-color: #{$green4};
    --homepage-slider-slider-crumb-hover-bg-color: #{$green5};
    --homepage-slider-arrow-bg-color: #{$white};
    --homepage-slider-arrow-hover-bg-color: #{$green5};
}

.homepage-slider {
    h1 {
        font-size: 6.2rem;
        text-transform: uppercase;
        color: var(--homepage-slider-color);
        margin-bottom: 3rem;

        @include mobile {
            font-size: 3rem;
            margin-bottom: 1.4rem;
        }
    }
}

.Slider {
    .Slider-Image {
        width: 10px;
        height: 10px;
    }

    .SliderWidget-FigureImage {
        padding: 0;
        height: auto;

        .Image-Image {
            position: static;
            object-fit: cover;
        }
    }

    .SliderWidget-Figcaption {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 10px;

        @include mobile {
            padding: 0;
            height: 100%;
        }

        @include desktop {
            max-width: $layout__max-width;
            padding: 0 100px;
        }
    }

    .Slider-Arrows {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        @include desktop {
            max-width: $layout__max-width;
        }

        .Slider-Left-Arrow,
        .Slider-Right-Arrow {
            top: 50%;
            position: absolute;
            background: var(--homepage-slider-color);
            padding: 0;
            border-radius: 50%;
            font-size: 0;
            width: 40px;
            height: 40px;

            &:hover {
                @include desktop {
                    background: var(--homepage-slider-arrow-hover-bg-color);
                }
            }

            &_isDisable {
                opacity: 0.5;

                &:hover {
                    @include desktop {
                        background: var(--homepage-slider-arrow-bg-color);
                    }

                    &::after {
                        @include desktop {
                            color: $default-primary-dark-color;
                        }
                    }
                }
            }
        }

        @include mobile {
            .Slider-Left-Arrow,
            .Slider-Right-Arrow {
                width: 30px;
                height: 30px;
            }
        }

        .Slider-Left-Arrow {
            left: 20px;
            @include slider-left-icon;

            &:after {
                margin-right: 3px;
            }
        }

        .Slider-Right-Arrow {
            right: 20px;
            @include slider-right-icon;

            &:after {
                margin-left: 3px;
            }
        }
    }

    .slider-block-wrap {
        background: rgba(242, 242, 242, .75);
        padding: 40px 30px;
        width: fit-content;

        @include mobile {
            padding-left: 60px;
            height: 99%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &.orange {
            color: #{$orange3};
        }
    }

    p {
        font-size: 20px;
        line-height: 22px;
        color: #{$dark3};
        text-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        letter-spacing: .5px;
        font-weight: $font-weight-regular;

        @include mobile {
            font-size: 16px;
            letter-spacing: .4px;
        }
    }

    .Button {
        margin-top: 30px;

        @include mobile {
            max-width: 120px;
        }
    }
}

.SliderOutter {
    .Slider-Crumbs {
        grid-gap: 7px;
        bottom: -20px;

        @include mobile {
            bottom: -25px;
        }
    }

    .Slider-Crumb {
        border: none;
        background: var(--homepage-slider-slider-crumb-bg-color);
        width: 10px;
        height: 10px;

        &:hover {
            @include desktop {
                background: var(--homepage-slider-slider-crumb-hover-bg-color);
            }
        }
    }

    .Slider-Crumb_isActive {
        background: var(--homepage-slider-slider-crumb-active-bg-color);
    }
}

.kemanapwa-home-page-main-blocks {
    @include mobile {
        @include fixed-width;
    }

    @include desktop {
        @include fixed-width;
    }
}