/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.FlashSaleHomeWidget {
    padding-top: 5rem;
    text-align: center;

    @include desktop-xxl {
        min-height: 660px;
    }

    &-Title {
        @include h3;
        @include lightning-icon-left;
        @include lightning-icon-right;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark2;
        margin-bottom: 15px;

        @include mobile {
            font-size: 2rem;
            line-height: 2.2rem;
        }
    }

    &-ProductListTitle {
        @include h4;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: $dark2;
    }

    &-ProductList {
        + .FlashSaleHomeWidget-ProductList {
            margin-top: 20px;
        }
    }

    &-TabContainer {
        margin-bottom: 2rem;
        text-align: center;
    }

    &-ButtonContainer {
        text-align: center;
    }

    &-Tab {
        font-size: 1.2rem;
        line-height: 1.4rem;
        letter-spacing: $letter-spacing-m;
        margin: 0 15px;
        color: var(--primary-dark-color);

        &_isActive {
            color: var(--primary-light-color);
            border-bottom: 1px solid var(--primary-light-color);
        }
    }

    &-TimerContainer {
        display: flex;
        justify-content: center;
    }

    &-Units {
        display: flex;
        padding: 1rem;
    }

    &-Unit {
        display: flex;
        align-items: center;

        > div {
            background-color: $white;
            border: 1px solid $grey14;
            width: 4rem;
            height: 4rem;
            line-height: 4rem;
            border-radius: 2px;
            margin: 0 1rem;
        }
    }

    .ProductListPage {
        padding-left: 0;
        padding-right: 0;
        text-align: left;
    }

    &-LoadingContainer{
        min-height: 400px;
        position: relative;
        .Loader{
            background: transparent;
        }
    }

    .ProductListWidget {
        padding-bottom: 20px;
        text-align: left;

        .ProductCard {
            &:nth-child(5),
            &:nth-child(6) {
                @include tablet {
                    display: flex;
                }

                @include desktop {
                    display: flex;
                }

                @include desktop-xl {
                    display: flex;
                }
            }

            &:nth-child(7),
            &:nth-child(8) {
                display: flex;
            }
        }
    }
}

.kemanapwa-home-page-flash-sale {
    margin-bottom: 50px;

    &.fixed-width {
        @include mobile {
            padding: 0;
        }
    }
}
