/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-background: #{$white};
    --header-color: #{$dark3};
    --header-contacts-color: #414141;
    --header-icon-stroke-width: 1px;
    --header-search-clear-background: #838383;
    --header-desktop-button-color: var(--primary-base-color);
    --header-height: 50px;
    --header-nav-height: 50px;
    --header-mobile-nav-height: 55px;

    --header-icon-color: #{$white};
    --header-icon-hover-color: #{$green4};
    --header-button-margins: 4.7rem;
    --header-title-color: var(--primary-dark-color);
    --header-top-icon-color: #{$dark3};
    --header-box-shadow: 0 5px 30px #0000001A;

    --header-button-bar-color: var(--primary-base-color);
    --header-total-height: 55px;
    --checkout-header-nav-height: 75px;

    @include desktop {
        --header-dots-color: #000;
    }

    @include after-mobile {
        --header-nav-height: 60px;
        --header-top-menu-height: 30px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height));
    }
}

@supports not (height: env(safe-area-inset-top)) {
    :root {
        --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height));
    }
}

@mixin button-invisible {
    opacity: 0;
    padding: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
}

@mixin button-visible {
    opacity: 1;
    padding: 7.5px 0;
    width: auto;
    pointer-events: all;
    overflow: visible;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

@mixin nav-button-text {
    cursor: pointer;
    color: var(--header-color);
    font-weight: $font-weight-regular;

    @include desktop-l {
      padding: 1rem 0;
    }
}

@mixin navigationTab-button {
    display: grid;
    text-align: center;

    &:hover {
        text-decoration: none;

        @include desktop {
            color: var(--header-icon-hover-color);
        }

        &:before {
            @include desktop {
                color: var(--header-icon-hover-color);
            }
        }
    }
}

@mixin nav-button-common {
    display: grid;
    text-align: center;

    &:before {
        margin-bottom: .5rem;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        @include desktop {
            color: var(--header-icon-hover-color);
        }

        &:before {
            @include desktop {
                color: var(--header-icon-hover-color);
            }
        }
    }

    @include desktop-xl {
        grid-template-columns: auto auto;
        align-items: center;
        font-size: 16px;

        &:before {
            margin-bottom: 0;
            margin-right: .7rem;
        }
    }

    @include before-desktop-l {
        display: none;
    }
}

.Header {
    --header-logo-width: 0;
    --header-logo-height: 0;

    position: fixed;
    top: var(--demo-notice-height);
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-top: env(safe-area-inset-top, 0);
    box-shadow: var(--header-box-shadow);

    @include mobile {
        --header-box-shadow: 0 3px 6px #0000000d;

        z-index: 999;

        &_name {
            &_filter {
                .Header-Title {
                    position: relative;
                }
            }
        }
    }

    @include before-desktop-l {
        --header-nav-height: 55px;

        .Header-ShipToWrapper,
        .Topbar {
          display: none;
        }

        &_name_default,
        &_name_popup {
          --header-background: #{$white};

          .Header-ShipToWrapper {
            display: grid;
          }
        }

        &_name_default {
            .Topbar {
                display: block;
              }
        }

        &_name_popup {
          .Header-LogoWrapper {
            opacity: 1;
          }
        }
    }

    @include desktop-l {
        --header-background: #{$white};
    }

    @include print-only {
        box-shadow: none;
        position: static;
    }

    @at-root .hiddenHeader {
        @include before-desktop {
            /* stylelint-disable-next-line length-zero-no-unit */
            --header-height: 0px;
        }
    }

    &-Wrapper {
        --header-total-height: 55px;

        margin-bottom: var(--header-total-height);

        @include desktop-l {
            --header-total-height: 135px;

            &_isCheckout,
            &_isCallbackStep {
                --header-total-height: 0;

                .Header-LogoWrapper {
                    --header-logo-width: 230px;

                    .Image-Image {
                        --header-logo-height: 45px;
                    }
                }
            }

            &_isCallbackStep {
                .Header-Nav {
                    @include desktop {
                        justify-content: flex-end;
                        height: var(--checkout-header-nav-height);
                        padding: 0 3rem;
                    }
                }
            }
        }

        @include print-only {
            margin-bottom: 0;
            height: auto;
            display: none;
        }

        &_isCallbackStep {
            .Topbar,
            .Header-Button_type_minicart,
            .Header-MyAccount,
            .Header-WishList {
                display: none;
            }

            .Menu-MenuWrapper,
            .SearchField {
                @include desktop {
                    display: none;
                }
            }
        }
    }

    &_isHiddenOnMobile {
        @include before-desktop {
            pointer-events: none;
            opacity: 0;
        }
    }

    &_name {
        &_cart {
            .Header-Button_type_minicart {
                @include desktop {
                    --header-color: var(--primary-base-color);
                }
            }
        }

        &_cart_overlay {
            .Header-Button_type_minicart,
            .Header-MinicartTitle {
                @include after-mobile {
                    --header-color: var(--primary-base-color);
                }
            }
        }

        &_menu,
        &_menu_subcategory {
            .Header-Button_type {
                &_close {
                    @include desktop {
                        @include button-visible;
                    }
                }

                &_menu {
                    @include desktop {
                        @include button-invisible;
                    }
                }
            }
        }

        &_customer_account,
        &_customer_sub_account {
            .Header-Button_type_account,
            .Header-MyAccountTitle {
                @include desktop {
                    --header-color: var(--header-desktop-button-color);
                }
                margin: auto;
            }
        }

        &_checkout {
            .Header-Button,
            .Header-SearchWrapper,
            .SearchField {
                @include desktop {
                    display: none;
                }
            }
        }

        @include mobile {
            &_order_track {
                .Header-LogoWrapper_isVisible {
                    opacity: 0;
                    max-width: unset;
                }
            }
        }
    }

    &_isCheckout {
        opacity: 1;

        .Header {
            &-Button_type_account {
                display: block;

                @include mobile {
                    &:before {
                        color: $dark3;
                        line-height: 2rem;
                    }
                }
            }

            &-Nav {
                @include desktop {
                    justify-content: flex-end;
                    height: var(--checkout-header-nav-height);
                    padding: 0 3rem;
                }
            }

            &-MyAccount {
                @include before-desktop {
                    position: absolute;
                    right: 10px;
                    width: auto;
                }
            }

            &-WishList {
                display: none;
            }

            &-LogoWrapper {
                svg {
                    @include desktop {
                        width: 230px;
                        height: 45px;
                    }
                }
            }
        }

        .MyAccountOverlay {
            @include before-desktop {
                top: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }

        .Topbar {
            display: none !important;
        }
    }

    &-CheckoutStep {
        width: 260px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: inline-block;

        @include mobile {
            display: none;
        }

        li {
            width: 130px;
            display: inline-block;
            position: relative;
            text-align: center;
            vertical-align: top;
            padding-left: 0;
            margin-bottom: 0;

            &::before {
                position: static;
                padding-right: 0;
                display: inline-block;
                width: 100%;
                width: 27px;
                height: 27px;
                border: 1.5px solid $green2;
                border-radius: 50%;
                margin-bottom: 4px;
            }

            span,
            a {
                display: inline-block;
                width: 100%;
                font-size: 1.2rem;
                letter-spacing: .3px;
                color: $grey13;
                text-transform: uppercase;
            }

            a {
                @include desktop {
                    position: absolute;
                    left: 0;
                    padding-top: 36px;
                }
                
                &:hover,
                &:focus {
                    @include desktop {
                        text-decoration: none;
                    }
                }
            }

            &:first-of-type {
                &::after {
                    background: $green5;
                    top: 15px;
                    content: '';
                    width: 83px;
                    height: 1px;
                    right: -32%;
                    position: absolute;
                }
            }

            &.Header-ShippingStep {
                @include checkout-shipping-icon;

                &_isActive {
                    &::before {
                        background-color: var(--primary-base-color);
                        border-color: var(--primary-base-color);
                        color: $white;
                    }

                    span {
                        color: var(--primary-dark-color);
                    }
                }
            }

            &.Header-BillingStep {
                @include checkout-billing-icon;

                &_isActive {
                    &::before {
                        background-color: var(--primary-base-color);
                        border-color: var(--primary-base-color);
                        color: $white;
                    }

                    span {
                        color: var(--primary-dark-color);
                    }
                }
            }
        }
    }

    &-Banner {
        background: var(--primary-base-color);
        color: $white;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: block;
        z-index: 100;

        strong {
            margin-left: 3px;
        }
    }

    &-TopMenu,
    &-Nav {
        display: flex;
        margin: auto;
        max-width: var(--content-wrapper-width);
    }

    &-TopMenu {
        height: var(--header-top-menu-height);
        justify-content: space-between;
        z-index: 21;
        padding: {
            left: 1.2rem;
            right: 2.4rem;
            top: 1.2rem;
        };

        @include mobile {
            padding: {
                left: 1.4rem;
                right: 2.8rem;
                top: 1.4rem;
            }
        }
    }

    &-Contacts {
        font-size: 1.2rem;
        color: var(--header-contacts-color);

        span:first-child {
            margin-right: 20px;
        }
    }

    &-Nav {
        align-items: center;
        height: var(--header-nav-height);
        padding: 0 1.2rem;
        z-index: 20;
        @include fixed-width;
        flex-wrap: wrap;

        @include mobile {
            padding: 0 1.5rem;
        }

        @include desktop {
            justify-content: flex-end;
            display: grid;
            grid-template-columns: 235px 1fr auto auto auto auto auto;
            grid-template-areas: "logo search shipto vstore myaccount wishlist minicart";
            align-items: center;
            height: 65px;
        }

        @include desktop-xl {
            grid-template-columns: 300px 1fr auto auto auto auto auto;
        }

        @include print-only {
            padding-left: 0;
            padding-right: 0;
            height: 0;
        }
    }

    &-Button {
        @include button-invisible;

        cursor: pointer;
        margin: 0;
        height: 35px;
        position: relative;

        @include print-only {
            display: none
        }

        &_isVisible {
            @include before-desktop {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu,
            &_account,
            &_minicart {
                @include desktop {
                    opacity: 1;
                    pointer-events: all;
                    overflow: visible;
                }
            }

            &_close {
                @include close-button;
            }

            &_back {
                @include menu-back-icon;

                @include mobile {
                    width: auto;
                    line-height: 2.6rem;
                }
            }

            &_menu {
                @include navigationTab-button;
                @include menu-icon;

                @include desktop {
                    width: 35px;
                }
            }

            &_account {
                @include before-desktop-l {
                    @include navigationTab-button;
                    @include my-account-icon;
                }

                @include desktop-l {
                    width: 0;
                    height: 0;
                    padding: 0;
                }

                &.Header-Button_isVisible {
                    overflow: visible;

                    @include desktop-l {
                        overflow: hidden;
                    }
                }
            }

            &_wishlist {
                @include before-desktop-l {
                    @include navigationTab-button;
                    @include wishlist-icon;
                }
            }

            &_minicart {
                @include before-desktop-l {
                    @include mini-cart-icon;
                    @include navigationTab-button;
                }

                @include desktop-l {
                    grid-area: minicart;
                    padding: 0;
                    cursor: default;
                    height: auto;
                    width: auto;
                }
            }

            &_ordertracker {
                @include before-desktop-l {
                    display: grid;
                    text-align: center;

                    @include order-tracker-icon;

                    &::before {
                        margin-bottom: 5px;
                    }
                }
            }

            &_searchClear {
                @include clear-search-button;
            }

            &_clear {
                @include icon($icon-remove-product, before, 2rem, var(--header-top-icon-color));

                &:before {
                    line-height: 1.8rem;
                }
            }

            &_edit {
                text-transform: uppercase;
            }

            &_share {
                @include share-button-wishlist;
            }

            &_home {
                @include navigationTab-button;
                @include home-icon;

                @include before-desktop-l {
                  padding: 0;
                  overflow: visible;
                  opacity: 1;

                  &:before {
                    font-weight: 600;
                  }
                }
            }

            &_ok,
            &_cancel {
                font-size: 1.4rem;
            }

            &_compare {
                @include button-visible;

                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-CompareButtonWrapper {
        border-right: 2px dotted var(--header-dots-color);
        margin: 0 20px 0 7px;
        padding-right: 20px;
    }

    &-CompareIcon {
        @include compare-icon(var(--header-color));
    }

    &-MyAccount {
        grid-area: myaccount;
        margin-left: var(--header-button-margins);

        &Wrapper {
            align-items: center;
            display: flex;
        }

        &Title {
            cursor: pointer;
            padding-right: .48rem;
            color: var(--header-color);
            margin: auto;

            @include nav-button-text;
            @include nav-button-common;
            @include my-account-icon;

            @include mobile {
                padding-right: .56rem;
                display: none;
            }
        }
    }

    &-Minicart {
        &ButtonWrapper {
            align-items: center;
            cursor: pointer;
            height: 26px;
            margin-left: 2.4rem;
            padding-right: 2.16rem;

            @include nav-button-text;
            @include nav-button-common;
            margin-left: var(--header-button-margins);
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @include mobile {
                margin-left: 2.8rem;
                padding-right: 2.52rem;
            }

            @include tablet-portrait {
                margin-left: 0;
            }

            &:hover {
                .Header-MinicartTitle {
                    @include desktop {
                        color: var(--header-icon-hover-color);
                    }
                }


                .Header-MinicartIcon {
                    &:before {
                        @include desktop {
                            color: var(--header-icon-hover-color);
                        }
                    }
                }
            }
        }

        &Title {
            color: var(--header-color);
            margin-right: 0;
            order: 2;

            @include mobile {
                font-size: 1rem;
            }
        }

        &Icon {
            @include mini-cart-icon;
            order: 1;
            margin-bottom: .5rem;
            top: 0;
            height: 25px;
            width: 100%;

            &::before {
                line-height: normal;
            }

            @include desktop-xl {
                height: 20px;
                width: 20px;
                top: 1px;
            }

            @include desktop-xl {
                margin-bottom: 0;
                margin-right: 1.5rem;
            }
        }

        &ItemCount {
            font-family: $base-font-family;
            min-width: 16px;
            min-height: 16px;
            background: var(--header-desktop-button-color);
            border-radius: 10px;
            color: var(--header-background);
            display: inline-block;
            font-size: 1.1rem;
            line-height: 1.2rem;
            padding: 3px 0 1px;
            position: absolute;
            transform: translateX(3px);
            z-index: 2;
            text-align: center;

            @include after-mobile {
                right: 10px;
                top: 5px;
            }

            @include desktop-xl {
                right: inherit;
                left: 14px;
            }

            @include mobile {
                padding-top: 4px;
                min-width: 18px;
                min-height: 18px;
                left: 50%;
                top: -5px;
                background: $white;
                font-size: 1.0rem;
                line-height: 1.1rem;
            }
        }
    }

    &-LogoWrapper {
        --header-logo-height: 34px;
        --header-logo-width: 154px;

        width: 154px;
        height: 34px;
        position: absolute;
        height: var(--header-logo-height);
        width: var(--header-logo-width);
        max-width: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        opacity: 0;
        overflow: hidden;
        margin: 9px auto 12px;
        position: relative;

        transition-property: opacity;
        transition-duration: 200ms;

        @include print-only {
            display: none;
        }

        @include desktop {
            --header-logo-height: 36px;

            height: 36px;
            @include logo-visible;
        }

        @include desktop-l {
            --header-logo-width: 215px;
            
            width: 215px;
            height: inherit;
            grid-area: logo;
            display: block;
            margin: auto 0;
            padding: 15px 0;
        }

        @include before-desktop-l {
            opacity: 0;

            &_isVisible {
              opacity: 1;

              svg {
                  max-height: 3.3rem;
              }
            }
        }

        @include desktop-xl {
            --header-logo-width: 278px;

            width: 278px;
        }

        &_isVisible {
            @include logo-visible;
        }

        .Image {
            padding: 0;
            overflow: visible;
            height: auto;
        }

        .Image-Image {
            height: 30px;
            width: 152px;
            object-fit: cover;
            position: static;

            @include desktop {
                width: 183px;
                height: 36px;
            }
        }
    }

    &-WishList {
        grid-area: wishlist;
        @include nav-button-text;
        @include wishlist-icon;
        margin-left: var(--header-button-margins);
        @include nav-button-common;
    }

    &-Title {
        opacity: 0;
        max-width: 0;
        transition-property: opacity;
        transition-duration: 200ms;
        text-align: center;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: var(--header-title-color);
        letter-spacing: $letter-spacing-m2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 1.8rem;
        line-height: 1.8rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include mobile {
            overflow: hidden;
            white-space: nowrap;
        }

        &_isVisible {
            @include before-desktop {
                opacity: 1;
                max-width: calc(100% - 55px);
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                white-space: normal;
                height: 35px;
            }

            @include print-only {
                font-size: 24px !important;
                line-height: 32px !important;
                height: 32px !important;
                max-width: 100%;
                position: static;
                text-align: left;
            }
        }
    }

    a {
        &:hover,
        &:focus {
            @include before-desktop {
                text-decoration: none;
            }
        }
    }

    @include desktop-l {
        &-Button_type {
            &_cancel,
            &_back,
            &_close,
            &_share,
            &_clear {
                display: none;
            }
        }
    }

    // Checkout Page customization
    &-Wrapper_isCallbackStep,
    &-Wrapper_isShipingStep,
    &-Wrapper_isBillingStep,
    &-Wrapper_isSuccessStep {
        .Topbar,
        .Header-VirtualStoreTLink,
        .Header-ShipToWrapper,
        .Header-WishList,
        .SearchField {
            display: none;
        }

        .Header-LogoWrapper {
            &_isVisible {
                @include mobile {
                    opacity: 0;
                }
            }
        }

        .Menu-MenuWrapper {
            @include desktop {
                display: none;
            }
        }

        .Header_isMenuActive {
            .Header-Nav {
                .Header-LogoWrapper_isVisible {
                    opacity: 1;
                }

                .SearchField {
                    display: inline-block;
                }
            }
        }
    }

    &-Wrapper_isCallbackStep,
    &-Wrapper_isSuccessStep {
        .Header {
            .Header-Button_type_minicart {
                display: none;
            }

            .Header-MyAccount {
                display: none;
            }
        }
    }

    &_isMenuActive {
        @include mobile {
            background-color: transparent;
            box-shadow: none;
            height: 100vh;
        }

        .InstallPrompt {
            @include mobile {
                display: none;
            }
        }

        .Header {
            &-Button_type_back {
                @include mobile {
                    display: none;
                }
            }

            &-Title_isVisible {
                @include mobile {
                    display: none;
                }
            }

            &-LogoWrapper {
                @include mobile {
                    opacity: 1;
                    max-width: 100%;
                    position: absolute;
                    z-index: 1;
                }
            }

            &-Nav {
                @include mobile {
                    box-shadow: var(--header-box-shadow);
                    background: var(--header-background);
                }

                .SearchField {
                    @include mobile {
                        @include animation-right-show;

                        max-width: 40%;
                        position: absolute;
                        z-index: 3;
                    }

                    &-Input {
                        @include mobile {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .Menu {
            &-MenuWrapper {
                @include mobile {
                    max-width: 40%;
                    height: calc(100vh - 80px);
                    box-shadow: var(--header-box-shadow);
                    padding: 0 0 150px;
                    overflow-y: auto;

                    @include animation-right-show;

                    position: absolute;
                    width: 100%;
                    pointer-events: all;
                }

                .MenuItem-CompareLink {
                    @include mobile {
                        display: flex;
                    }
                }
            }

            &-MainCategories {
                @include mobile {
                    padding: 0 2rem;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &-SubMenu {
                transform: translateX(-300px);
            }
        }

        &.Header_name_menu_subcategory {
            .Header {
                &-Title {
                    &_isVisible {
                        @include mobile {
                            z-index: 4;
                            top: inherit;
                            bottom: inherit;
                            left: inherit;
                            right: inherit;
                            max-width: 35%;
                            padding-left: 20px;
                            padding-right: 10px;
                            display: flex;
                        }
                    }
                }

                &-Button_type_back {
                    @include mobile {
                        box-shadow: var(--header-box-shadow);
                        background: var(--header-background);
                        height: var(--header-nav-height);
                        max-width: 40%;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        z-index: 4;
                        display: flex;
                        align-items: center;
                        padding: 0 2rem;
                    }
                }

                &-Nav {
                    .SearchField {
                        @include mobile {
                            pointer-events: none;
                        }
                    }
                }
            }

            .Menu {
                &-MenuWrapper {
                    @include mobile {
                        overflow-y: hidden;
                    }

                    .MenuItem-CompareLink,
                    .Menu-ItemFigure_type_main {
                        @include mobile {
                            display: none;
                        }
                    }
                }

                &-SubMenu {
                    @include mobile {
                        padding-top: 0;
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    &-HeaderOutsideClick {
        @include mobile {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.08);
            z-index: 2;

            span {
                display: none;
            }
        }

        @include desktop {
            display: none;
        }
    }
}

.Header_name_cart_overlay {
    .Header-MinicartItemCount {
        color: $white;
    }
}
