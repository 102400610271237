/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-background-color: #{$white};
    --product-card-padding: 10px 9px;
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #{$grey14};
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(255, 255, 255, .75);
    --product-card-wishlist-button-background: #{$grey14};
    --product-card-wishlist-button-color: #{$dark2};
    --product-card-wishlist-button-hover-color: #{$red3};
    --product-card-compare-button-background: #{$grey14};
    --product-card-compare-button-color: #{$dark2};
    --product-compare-button-hover-color: #{$default-primary-light-color};
    --product-card-name-line-height: 1.2em;
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 15px;

    --product-card-wishlist-button-width: 29px;
    --product-card-wishlist-background: #{$white};
    --product-card-wishlist-heart-size: 17px;
    --product-card-compare-button-width: 29px;
    --product-card-compare-size: 13px;
}

.ProductCard {
    padding-left: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;
    margin-bottom: 0;
    transition: $transition1;

    @include mobile {
        margin-bottom: 10px;
        position: relative;
    }

    &:hover,
    &:focus {
        .ProductCard-ProductActions {
            @include desktop-l {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &:nth-child(1),
    &:nth-child(2) {
        @include mobile {
            margin-top: 0;
        }
    }

    &-Exclusiveprice {
        margin-bottom: 5px;
        color: $green8;
        font-weight: $font-weight-regular;
    }

    &::before {
        content: none;
    }

    &-Content {
        padding: 8px 0 0;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        position: relative;

        .ProductCard-Link {
            padding: 0;
            border: 0 !important;
        }

        .Button {
            --button-padding: 12px 5px 11px;
            --button-text-transform: capitalize;
            --button-color: var(--button-primary-revert-color);
            --button-background: var(--button-primary-revert-background);
            --button-hover-border: var(--button-primary-revert-hover-border);
            --button-hover-background: var(--button-primary-revert-hover-background);

            margin: 15px auto 0;
            min-width: 119px;
        }
    }

    .ARLink-Container {
        width: auto;
        position: absolute;
        right: 0;
        bottom: 4px;
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        color: $black;
        margin: 0 0 8px;
        overflow: hidden;
        font-weight: $font-weight-regular;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: .35px;
        height: 33px;

        @include desktop {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-Brand {
        font-weight: 300;
        opacity: .5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1.1rem;
        display: none;

        @include after-mobile {
            font-size: 1.2rem;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-bottom: 100%;

        @include mobile {
            padding-bottom: 0;
            height: auto;
        }

        img {
            @include mobile {
                position: static;
            }
        }

        .Image-Image {
            &::after {
                @include mobile {
                    background: none;
                }
            }
        }
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        bottom: 0;
        margin: auto;
        top: 0;
    }

    &-FigureReview {
        display: flex;
        justify-content: center;

        @include mobile {
            position: relative;
        }
    }

    &-Reviews {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 106px;
        height: 26px;
        position: absolute;
        bottom: 0;
        background-color: var(--product-card-reviews-background);
        border-radius: 4px;
        letter-spacing: normal;
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        display: none;
    }

    &-Color {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin: 5px 7px 0 0;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 5px 7px 0 0;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        font-size: 14px;
        letter-spacing: .49px;
        color: $default-primary-dark-color;
        font-weight: $font-weight-bold;

        .ProductPrice {
            font-size: 14px;
            letter-spacing: .49px;
            line-height: 22px;
            font-weight: $font-weight-bold;
        }
    }

    &-Price {
        display: flex;
        flex-wrap: wrap;

        ins {
            width: 100%;
        }

        del {
            margin-left: 0;
            letter-spacing: $letter-spacing-m2;
            font-size: 1.4rem;
            line-height: 11px;
            color: $dark3;
            font-weight: $font-weight-regular;
            line-height: 1.6rem;
            margin-right: 10px;
        }
    }

    &-PriceBadge {
        color: var(--primary-dark-color);
        font-size: 14px;
        margin-bottom: 0;
        display: inline-block;
        margin-right: 5px;
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: var(--product-card-background-color);
        padding: var(--product-card-padding);
        border-radius: 5px;
        border: 1px solid var(--product-card-color-border-color);

        &:hover,
        &:focus {
            text-decoration: none;

            @include desktop {
                border: 1px solid $default-primary-base-color;
            }
        }
    }

    &-ProductActions {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        @include animation;

        @include desktop-l {
            opacity: 0;
            visibility: hidden;
        }
    }

    &-WishListButton {
        background-color: var(--product-card-wishlist-button-background);
        border: none;
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    .ProductWishlistButton {
        margin: 0 0 10px 0;
        overflow: hidden;

        &_isActive {
            > .Button {
                background-color: rgba(235, 235, 235, 0.75);
            }

            .ProductWishlistButton-Heart {
                @include product-wishlist-icon-active;
                line-height: normal;
            }
        }

        @include desktop {
            &:hover {
                > .Button {
                    background-color: rgba(235, 235, 235, 0.75);
                }
            }
        }

        &-Button {
            --button-hover-background: rgba(235, 235, 235, 0.75);
            --button-hover-border: var(--product-card-wishlist-background);

            width: var(--product-card-wishlist-button-width);
            height: var(--product-card-wishlist-button-width);
            background: var(--product-card-wishlist-button-background);
            border: none;


            &_isInWishlist {
                --button-background: var(--product-card-wishlist-background);
                --button-hover-background: var(--product-card-wishlist-background);
                --button-hover-border: var(--product-card-wishlist-background);

                .ProductWishlistButton-Heart {
                    @include product-wishlist-icon-active;
                }
            }
        }

        &-Heart {
            top: 2px;
            left: .5px;
            @include product-wishlist-icon;
        }
    }

    .ProductCompareButton {
        margin: 0 0 10px 0;

        &-Button {
            --button-radius: 50%;
            --button-hover-background: rgba(235, 235, 235, 0.75);

            width: var(--product-card-compare-button-width);
            height: var(--product-card-compare-button-width);
            background-color: var(--product-card-wishlist-button-background);
            border: none;
        }

        &-Icon {
            @include product-compare-icon;
        }

        &_isActive {
            > .Button {
                @include desktop {
                    background-color: rgba(235, 235, 235, 0.75);
                }
            }

            .ProductCompareButton-Icon {
                &::before {
                    color: var(--product-compare-button-hover-color);
                }
            }
        }

        @include desktop {
            &:hover {
                > .Button {
                    background-color: rgba(235, 235, 235, 0.75);
                }
            }
        }
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }

    &-InstallmentLabel {
        border: 1px solid var(--menu-background-desktop);
        color: var(--menu-background-desktop);
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
        padding: 1px 5px;
        margin-top: 5px;

        span {
            margin-right: 5px;

            @include mobile {
                width: 100%;
            }
        }
    }
}
