.kemanapwa-home-page-promo-banner {
    padding-bottom: 40px;

    @include mobile {
        padding-bottom: 0;
        margin-top: 2rem;
    }

    h2 {
        text-align: center;
        line-height: 31px;
        margin-bottom: 20px;
    }
}
