/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$select-width: 160px !default;
$select-height: 40px !default;
$select-option-height: 40px !default;
$select-arrow-height: 7px !default;
$select-arrow-width: 7px !default;

:root {
    --select-option-background: #fff;
    --select-option-hover-background: #{$green5};
    --input-top-border-color-focus: #{$green5};
    --input-option-text-color: #{$dark1};
}

.FieldSelect {
    cursor: pointer;

    @include mobile {
        position: relative;
    }

    &::after {
        content: '';
        display: block;
        width: $select-arrow-width;
        height: $select-arrow-height;
        transform: translateY(-2px) rotate(45deg);
        pointer-events: none;

        position: absolute;
        right: 20px;
        bottom: 0;
        top: 0;
        margin: auto;

        border-style: solid;
        border-color: $dark3;
        border-width: 0 1px 1px 0;
    }

    &[aria-expanded="true"] {
        &:after {
            @include desktop {
                bottom: -5.5px;
                transform: translateY(-2px) rotate(-135deg);
            }
        }
    }

    &_isExpanded::after {
        @include desktop {
            bottom: $select-arrow-height * 3/2;
            transform: translateY(-2px) rotate(-135deg);
        }
    }

    &-Select {
        width: 100%;
        min-width: 100%;
        padding-right: 40px;
        color:  var(--input-option-text-color);
        text-transform: capitalize;

        @include desktop {
            pointer-events: none;
        }

        &_isExpanded {
            border-color: var(--input-color);
        }
    }

    &-Options {
        position: absolute;
        width: 100%;
        top: 93%;
        left: 0;
        z-index: 40;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        text-transform: capitalize;

        overflow-y: auto;
        transition: 200ms max-height;
        will-change: max-height;
        max-height: 0;
        -webkit-overflow-scrolling: touch;
        color:  var(--input-option-text-color);

        // Firefox support
        // scrollbar-width: none !important;
        // scrollbar-color: none !important;

        // &::-webkit-scrollbar {
        //     display: none;
        // }

        // &::-webkit-scrollbar-thumb {
        //     display: none;
        // }

        &_isExpanded {
            border-radius: 0 0 5px 5px;
            background: var(--select-option-background);

            @include desktop {
                max-height: 200px;
                border-color: var(--input-border-color-focus);
                border-top-color: transparent;
            }
        }
    }

    &-Option {
        padding: 0;
        margin: 0;
        z-index: 1;
        transition: 100ms max-height ease-in;
        will-change: max-height;
        max-height: $select-option-height;
        font-size: 1.4rem;
        line-height: 3.6rem;
        margin: 0 1.5rem;

        @include mobile {
            line-height: 4.2rem;
            padding-left: 2.1rem;
        }

        &::before {
            content: none;
        }

        &:first-of-type {
            border-top: 1px solid var(--input-top-border-color-focus);
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover,
        &:focus {
            &:before {
                content: '';
                background-color: var(--select-option-hover-background);;
                position: absolute;
                left: -1.5rem;
                right: -1.5rem;
                z-index: -1;
                top: 0;
                bottom: 0;
            }
        }
    }
}
