/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkbox-label-color: #0a0a0a;
    --my-account-input-label-color: rgba(10, 10, 10, .59);
    --my-account-overlay-width: 400px;
    --my-account-overlay-border-width: 1px;

    --MyAccountOverlay-width: 360px;
    --MyAccountOverlay-title-color: #{$dark3};
    --MyAccountOverlay-gaps-l: 1.5rem;
    --MyAccountOverlay-gaps-xl: 2rem;
    --MyAccountOverlay-top: 7rem;
    --MyAccountOverlay-top-active: 6.5rem;
    --MyAccountOverlay-top-active-xl: 5.5rem;
}

.MyAccountOverlay {
    --overlay-background: var(--overlay-content-background);

    justify-self: flex-end;
    grid-column: 3;
    right: 0;
    transition: 380ms ease-in all;

    @include desktop {
        position: absolute;
        padding: 0;
        right: 0;
        top: var(--MyAccountOverlay-top);
        border-radius: 0 0 5px 5px;
        box-shadow: 0px 3px 6px #00000026;
        width: var(--MyAccountOverlay-width);
    }

    @include before-desktop {
        height: auto;
        padding-bottom: 0;
        top: 0;
        position: relative;
    }

    &-Action_state {
        &_createAccount {
            .MyAccountOverlay-Heading {
                text-transform: capitalize;
            }

            .Field-Label {
                text-transform: capitalize;
            }
        }

        &_forgotPassword {
            @include mobile {
                .MyAccountOverlay-Additional_state_forgotPassword {
                    display: block;
                    padding-top: .8rem;
                    padding-left: 1.4rem;
                }
            }
        }
    }

    > * {
        background-color: transparent;
    }

    &_isVisible {
        @include desktop-l {
            top: var(--MyAccountOverlay-top-active);
        }

        @include desktop-xl {
            top: var(--MyAccountOverlay-top-active-xl);
        }
    }

    button {
        width: 100%;
    }

    input {
        width: 100%;
    }

    &-Heading {
        font-size: 2rem;
        font-weight: $font-weight-bold;
        color: var(--MyAccountOverlay-title-color);
        letter-spacing: $letter-spacing-m4;
        line-height: 3.1rem;
        padding: 0 1.2rem 0;

        @include mobile {
            padding: 0 1.4rem 1.4rem;
        }

        @include before-desktop {
            display: none;
        }
    }

    &-Notification {
        background-color: var(--primary-dark-color);
        padding: 2.4rem;

        @include mobile {
            padding: 2.8rem;
        }
    }

    &-Navigation {
        li {
            margin: 0;
            padding: 0;

            &::before {
                content: none;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                display: block;
            }
        }

        a,
        button {
            width: 100%;
            text-align: left;
            padding: 1.2rem 2.4rem;
            font-size: 1.44rem;

            @include mobile {
                padding: 1.4rem 2.8rem;
                font-size: 1.68rem;
            }

            @include desktop {
                padding: .6rem;

                &:hover,
                &:focus  {
                    background-color: var(--color-cart-light);
                    text-decoration: none;
                }
            }
        }
        @include desktop {
            padding: .6rem;
        }
    }

    &-Action form,
    &-Additional {
        padding: {
            top: 0;
            left: 1.2rem;
            right: 1.2rem;
            bottom: 1.2rem;
        };

        @include mobile {
            padding: {
                left: 1.4rem;
                right: 1.4rem;
                bottom: 1.4rem;
            }
        }

        &_state_signIn {
            padding-bottom: 0;
        }
    }

    &-Action {
        display: grid;
        grid-template-rows: max-content 1fr;
        width: 100%;
        padding: 0 .6rem;

        @include desktop {
            width: var(--MyAccountOverlay-width);
            padding: 2rem .8rem;
        }

        &_state {
            &_createAccount {
                h4 {
                    padding-right: 1.2rem;
                    display: inline;

                    @include mobile {
                        padding-right: 1.4rem;
                    }
                }

                h3 {
                    margin-bottom: 1.2rem;

                    @include mobile {
                        margin-bottom: 1.4rem;
                    }
                }

                .Field {
                    margin-top: .84rem;

                    @include mobile {
                        margin-top: .98rem;
                    }
                }

                .MyAccountOverlay {
                    &-Legend {
                        margin-top: 2rem;

                        legend {
                            font-size: 1.2rem;
                            line-height: 18px;
                            letter-spacing: .3px;
                            padding-bottom: 0;
                            color: $default-primary-dark-color;
                        }

                        .Field {
                            margin-top: var(--MyAccountOverlay-gaps-l);
                        }
                    }

                    &-FieldWrap {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 20px;
                    }
                }
            }

            &_forgotPassword {
                h4 {
                    width: initial;
                }

                button {
                    font-weight: bold;
                }
            }

            &_signIn {
                h4 {
                    text-align: center;
                }
            }

            &_loggedIn {
                .MyAccountOverlay-Heading {
                    @include desktop {
                        display: none;
                    }
                }
                @include desktop {
                    width: 180px;
                    padding: 0;
                }
            }
        }

        h4 {
            font-size: 1.38rem;
            font-weight: $font-weight-regular;
            margin-bottom: .6rem;

            @include mobile {
                font-size: 1.61rem;
                margin-bottom: .7rem;
            }
        }

        h3 {
            margin-top: 0;
        }

        form {
            padding-bottom: 0;

            .Button {
                &_likeLink {
                    margin: var(--MyAccountOverlay-gaps-xl) 0;
                    text-align: center;
                    font-size: 1.2rem;
                    font-weight: $font-weight-bold;
                    letter-spacing: $letter-spacing-m4;
                    line-height: 1.8rem;
                    color: $green3;
                }
            }
        }
    }

    &-Additional {
        section {
            display: flex;
            padding-top: 20px;

            &:first-of-type {
                padding-top: 0;
            }
        }

        &_state {
            &_createAccount {
                padding: 1rem 1.2rem 0 1.2rem;

                @include mobile {
                    padding-top: 2.8rem;
                }

                @include before-desktop {
                    display: none;
                }

                h4 {
                    margin: 0;
                    padding-right: 0;
                }

                button {
                    width: initial;
                    height: initial;
                    margin: 0;
                    padding-left: .48rem;
                    font-size: 1.2rem;
                    font-weight: $font-weight-bold;

                    @include mobile {
                        padding-left: .56rem;
                    }
                }
            }

            &_signIn {
                section {
                    flex-direction: column;
                }

                h4 {
                    margin-bottom: var(--MyAccountOverlay-gaps-l);
                    color: $dark1;
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    font-weight: $font-weight-light;
                    border-top: 1px solid $green5;
                    padding-top: var(--MyAccountOverlay-gaps-xl);
                }
            }

            &_confirmEmail {
                padding-top: 0;
            }

            &_forgotPassword {
                padding: 1rem 1.2rem 0 1.2rem;

                @include mobile {
                    padding-top: 3.92rem;
                    padding-left: 2.94rem;
                }

                @include before-desktop {
                    display: none;
                }

                section {
                    display: flex;

                    .Button {
                        width: initial;
                        margin-top: 0;
                        padding-left: 5px;
                        font-size: 1.2rem;
                        font-weight: $font-weight-bold;
                        color: $green3;
                    }

                    h4 {
                        margin-bottom: 0;
                        align-self: center;
                        color: $dark1;
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                        letter-spacing: $letter-spacing-m;
                    }

                    &:first-of-type {
                        padding-top: 1.4rem;
                    }

                    &:last-of-type {
                        padding-top: .6rem;
                    }
                }
            }

            &_forgotPasswordSuccess {
                padding-top: 0;

                @include mobile {
                    padding-top: 2rem;
                }

                h4 {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    padding-bottom: 1.68rem;
                    font-weight: normal;
                    letter-spacing: normal;

                    @include mobile {
                        font-size: 1.4rem;
                        padding-bottom: 1.96rem;
                    }
                }

                p {
                    font-weight: $font-weight-regular;
                }

                .Button {
                    margin-top: 2rem;
                }
            }
        }
    }

    &-CloseButton {
        &::before,
        &::after {
            background-color: var(--header-color);
            content: '';
            height: 20px;
            position: absolute;
            right: 27px;
            top: -13px;
            transform-origin: 50% 50%;
            width: 1px;
            z-index: 1;
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }

    &-ForgotPasswordButton {
        @include desktop {
            display: none;
        }
    }

    &-Buttons {
        margin-top: var(--MyAccountOverlay-gaps-xl);
    }

    &-Button {
        &_likeLink {
            font-size: 1.14rem;
            margin-top: 1.44rem;

            @include mobile {
                font-size: 1.33rem;
                margin-top: 1.68rem;
            }
        }
    }

    &-Checkbox {
        display: flex;
        flex-direction: column;
        align-items: normal;
        margin-bottom: 2.4rem;
        margin-top: 1.08rem;

        + .input-control {
            margin-right: 1rem;
        }

        @include mobile {
            margin-bottom: 2.8rem;
            margin-top: 1.26rem;
        }

        > label {
            &:first-of-type {
                align-items: center;
                color: var(--checkbox-label-color);
                font-size: 1.2rem;
                font-weight: 500;
                padding-bottom: 0;
            }

            &:last-of-type {
                height: auto;
                width: 100%;
                display: inline-block;
    
                input {
                    top: 0;
                    left: 0;
                    margin: 0;
                 }
    
                &::after {
                    left: .12rem;
                    top: .48rem;
                    width: .18rem;
                    height: .18rem;
    
                    @include mobile {
                        left: .14rem;
                        top: .56rem;
                        width: .21rem;
                        height: .21rem;
                    }
                }
            }
        }

        input {
            position: relative;
            width: 20px;
            height: 20px;
            left: 20px;
        }
    }

    &-SocialLogin {
        &Wrapper {
            border-top: 1px solid $green5;
            padding-top: var(--MyAccountOverlay-gaps-xl);
        }

        &Title {
            margin-bottom: var(--MyAccountOverlay-gaps-l);
            color: $dark1;
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: $font-weight-light;
            text-align: center;
        }

        &Inner {
            display: flex;
            justify-content: space-between;

            @include mobile {
                flex-wrap: wrap;
            }

            .Button {
                --button-padding: 0;
                --button-letter-space: 0;
                    
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 100%;
                line-height: 38px;

                &::before {
                    margin-right: 4px;
                    color: var(--button-background);
                }

                &:hover {
                    &::before {
                        @include desktop-xl {
                            color: var(--button-hover-color);
                        }
                    }
                }
            }

            .FacebookLogin {
                margin-bottom: 10px;

                .Button {
                    @include facebook-box-icon;
                }
            }

            .GoogleLogin {
                margin-bottom: 10px;

                .Button {
                    @include google-icon;

                    &::before {
                        margin-top: -2px;
                    }
                }
            }

            .AppleLogin {
                .Button {
                    @include apple-icon;

                    &::before {
                        margin-top: -2px;
                    }
                }
            }
        }

        &Button {
            width: 49%;
            padding: 0 3px;

            @include mobile {
                width: 100%;
            }
        }
    }
}
