/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --Product-label-out-of-stock-background: #{$grey12};
}

.ProductLabel {
    position: absolute;
    left: 0;

    @include desktop {
        left: -10px;
    }

    + .ProductSale-Label {
        top: 35px;

        @include desktop {
            top: 35px;
        }
    }

    &-SaleItem {
        height: 2.7rem;
        color: var(--ProductSale-label-color);
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: var(--ProductSale-label-font-weight);
        display: flex;
        align-items: center;
        padding: 2px 20px 2px 10px;
        border-radius: 3px 4px 21px 3px;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);

        @include mobile {
            font-size: 1rem;
            line-height: 1.9rem;
            height: 2.5rem;
        }

        &_outOfStock {
            background: var(--Product-label-out-of-stock-background);
        }
    }
}
