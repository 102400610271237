/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --table-secondary-color: #{$peach};
    --table-body-th-color: #{$dark3};
    --table-body-td-color: #{$darkblack};
}

table {
    thead tr {
        background-color: var(--table-secondary-color);
    }

    tbody tr {
        border-bottom: 1px solid #{$grey9};

        &:first-of-type {
            td {
                padding-top: 2rem;
            }
        }
    }

    td,
    th {
        font-size: 1.4rem;
        padding: 1.5rem;
        text-align: left;
        min-width: 14rem;
    }

    th {
        color: var(--table-body-th-color);
        text-transform: capitalize;

        @include mobile {
            width: 25%;
        }
    }

    td {
        font-weight: $font-weight-bold;
        color: var(--table-body-td-color);

        // @include mobile {
        //     width: 75%;
        // }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: scroll;
}
