/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --option-background-color: #{$white};
    --option-check-mark-background: #{$grey14};
    --option-padding: 0 12px;
    --option-margin: 0;
    --option-size: 35px;
    --option-text-color: #{$dark2};
    --option-border-color: #{$grey14};
    --swatch-border-color: #{$grey14};
    --swatch-check-mark-background: #{$grey12};
    --options-active-color: #{$green4};

    @include mobile {
        --option-border-color: #{$grey14};
        --option-text-color: #{$dark2};
    }
}

.ProductAttributeValue {
    --button-background: initial;
    --button-color: initial;
    --button-padding: 0;
    --option-is-selected: 0;

    margin-left: 0;

    @include mobile {
        position: relative;
    }

    &-Color,
    &-String,
    &-Image,
    &-Text {
        --option-margin: 1px 11px 0 1px;

        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: var(--option-text-color);
        display: inline-block;
        min-height: var(--option-size);
        width: var(--option-size);
        margin: var(--option-margin);
        padding: var(--option-padding);

        @include mobile {
            padding: 0;
        }
    }

    &-Image {
        padding: 0;
        border-radius: 50%;
        position: relative;
    }

    &-Image-Overlay {
        --option-check-mark-background: white;

        position: absolute;
        left: 0;
        margin-left: .6rem;

        @include mobile {
            margin-left: 0;
        }

        @include after-mobile {
            margin: 1px 0 0 1px;
        }
    }

    &-Color {
        margin-bottom: 0;
        position: relative;
    }

    &-Color,
    &-Image-Overlay {
        border-radius: 50%;
        font-size: 0;
        width: var(--option-size);
        height: var(--option-size);

        box-shadow: 0 0 0 1px var(--swatch-border-color);
        border: 2px solid $grey15;
        will-change: box-shadow, background-color;

        &::before,
        &::after {
            content: '';
            position: absolute;

            opacity: var(--option-is-selected);
            transition: opacity 200ms;
            will-change: opacity, border-left, border-right;
        }

        &::before {
            height: var(--option-size);
            width: var(--option-size);

            border-radius: inherit;
            box-shadow: 0 0 0 1px $green2;
            left: -2px;
            top: -2px;
        }

        &::after {
            height: calc(var(--option-size) / 8);
            left: calc(var(--option-size) / 3.5);
            top: calc(var(--option-size) / 3.2);
            width: calc(var(--option-size) / 3.6);

            transform: rotate(-45deg);

            border: {
                left: 2px solid var(--swatch-check-mark-background);
                bottom: 2px solid var(--swatch-check-mark-background);
            }
        }

        &:hover {
            @include after-mobile {
                --swatch-border-color: #{$green2};

                &:after {
                    --option-is-selected: 1;
                }
            }
        }
    }

    &-String,
    &-Text {
        border-style: solid;
        line-height: var(--option-size);
        min-width: calc(1.25 * var(--option-size));
        width: auto;
        text-align: center;

        &_isSelected {
            --option-border-color: var(--options-active-color);
            --option-text-color: var(--options-active-color);
        }
    }

    &-Text {
        display: flex;
        border: 0;
        align-items: baseline;
        margin: 0;
        line-height: 1.4;
        text-transform: capitalize;

        @include mobile {
            min-height: unset;
            margin-bottom: 1.5rem;
        }

        &_isSelected {
            color: var(--options-active-color);

            label {
                color: var(--options-active-color);
            }

            .input-control {
                --input-checkbox-checked-background-color: var(--options-active-color);

                &::after {
                    --checkmark-color: #{$white} !important;
                    left: .2rem;
                    top: .64rem;
                    width: .24rem;
                    height: .24rem;
                    box-shadow:
                        2px 0 0 var(--checkmark-color),
                        4px 0 0 var(--checkmark-color),
                        4px -2px 0 var(--checkmark-color),
                        4px -4px 0 var(--checkmark-color),
                        4px -6px 0 var(--checkmark-color),
                        4px -8px 0 var(--checkmark-color);
                    transform: rotate(45deg);
                    transition-property: background-color, box-shadow;
                    will-change: background-color, box-shadow;

                    @include mobile {
                        left: .35rem;
                        top: .84rem;
                        width: .28rem;
                        height: .28rem;
                    }
                }
            }
        }

        label {
            font-weight: 400;
            cursor: pointer;
            margin: 0;
            font-size: 1.2rem;
            line-height: 1.4rem;

            &:last-child {
                border-color: var(--option-border-color);
            }

            &:first-of-type {
                padding-bottom: 0;
                order: 1;
                text-align: left;
                text-overflow: ellipsis;
                line-height: 1.6rem;

                ~ label {
                    margin: 0;
                    min-width: 1.6rem;
                    top: .36rem;

                    @include mobile {
                        min-width: 2.1rem;
                        top: .42rem;
                    }
                }
            }
        }

        &:hover,
        &:focus {
            label {
                @include after-mobile {
                    border-color: var(--primary-base-color);
                }

                &::after {
                    @include after-mobile {
                        --checkmark-color: var(--secondary-dark-color);
                    }
                }
            }
        }

        @include after-mobile {
            padding: .6rem 0;
        }
    }

    &:hover,
    &:focus {
        @include desktop {
            --option-border-color: #{$black};
            --option-text-color: #{$green4};
        }

        text-decoration: none;
    }

    &:hover {
        @include desktop {
            .ProductAttributeValue-Text {
                label {
                    color: $green4;

                    .input-control {
                        border-color: $green4;

                        &:after {
                            --checkmark-color: #{$green4};
                            left: .2rem;
                            top: .64rem;
                            width: .24rem;
                            height: .24rem;
                            box-shadow:
                                2px 0 0 var(--checkmark-color),
                                4px 0 0 var(--checkmark-color),
                                4px -2px 0 var(--checkmark-color),
                                4px -4px 0 var(--checkmark-color),
                                4px -6px 0 var(--checkmark-color),
                                4px -8px 0 var(--checkmark-color);
                            transform: rotate(45deg);
                            transition-property: background-color, box-shadow;
                            will-change: background-color, box-shadow;

                            @include mobile {
                                left: .35rem;
                                top: .84rem;
                                width: .28rem;
                                height: .28rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &_isNotAvailable {
        opacity: .25;
        pointer-events: none;
    }

    p {
        font-weight: $font-weight-regular;
    }

    .Table-Wrapper {
        overflow: hidden;

        tr {
            border-bottom: 0;

            td {
                border: 0;
                height: auto;
                width: auto;
                background-color: transparent;
                font-weight: $font-weight-regular;
                padding: 4px;
                font-size: 1.2rem;
                color: $dark3;

                strong {
                    font-weight: $font-weight-regular;
                }
            }
        }

        @include mobile {
            table {
                width: 100%;
            }
        }
    }
}
