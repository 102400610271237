/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Yehan Virantha <yviranta@kemana.com>
 * http://www.kemana.com
 *
 */
 
:root {
    --banner-text-shadow: 0px 1px 6px #{$black};
}

.banner {
    .banner-img {
        display: flex;
        overflow: hidden;
        border-radius: 5px;

        @include mobile {
            height: auto;
        }

        .Image_ratio_square {
            padding: 0;
            overflow: visible;
            height: auto;
        }

        img {
            object-fit: cover;
            position: static;

            @include mobile {
                height: auto !important;
            }
        }
    }

    .banner-description {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 95%;

        .sub-title {
            margin-bottom: 5px;
        }

        Button {
            @include mobile {
                display: none;
            }
        }

        &.h-center {
            &.v-center {
                top: 50%;
                left: 50%;
                text-align: center;
                transform: translate(-50%, -50%);
            }
        }

        &.bottom {
            bottom: 0;
            top: unset;

            &.h-center {
                left: 50%;
                text-align: center;
                transform: translate(-50%, 0);
            }
        }

        &.top {
            top: 0;
            transform: translateY(0);

            &.h-center {
                left: 50%;
                text-align: center;
                transform: translate(-50%, 0);
            }

        }

        &.left {
            left: 0;
            text-align: left;
            transform: translateX(0);

            &.v-center {
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        &.right {
            right: 0;
            transform: translateX(0);
            text-align: right;
            left: unset;

            &.v-center {
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }
}

.banner-description {
    padding: 20px;

    @include mobile {
        text-align: center;
    }

    .Button {
        &.margin-l {
            margin-top: 29px;
        }

        &.margin-x {
            margin-top: 18px;
        }
    }

    .white {
        color: $white;
    }

    .no-bold {
        font-weight: $font-weight-regular;
    }

    .text-shadow {
        text-shadow: var(--banner-text-shadow);
    }
}

.half-width-banner {
    width: calc(50% - 10px);
    position: relative;

    @include mobile {
        width: 100%;
        margin-bottom: 10px;
    }
}
