/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --wishlist-button-width: 35px;
    --wishlist-button-radius: 50%;
    --wishlist-button-border: #{$grey7};
    --wishlist-button-border-hover: var(--primary-dark-color);
    --wishlist-button-background: #{$white};
    --wishlist-button-background-active: #{$grey9};
    --wishlist-heart-size: 18px;
    --wishlist-heart-color: #{$grey7};
    --wishlist-heart-color-hover: var(--primary-dark-color);
    --wishlist-heart-color-active: #{$red1};
}

.ProductWishlistButton {
    --loader-scale: .6;

    vertical-align: middle;
    display: inline-block;

    @include mobile {
        position: relative;
    }

    &-Button {
        --button-padding: 0;
        --button-background: var(--wishlist-button-background);
        --button-hover-background: var(--wishlist-button-background);
        --button-border: var(--wishlist-button-border);
        --button-hover-border: var(--wishlist-button-border-hover);

        width: var(--wishlist-button-width);
        height: var(--wishlist-button-width);

        &_isInWishlist {
            --button-padding: 0;
            --button-background: var(--wishlist-button-background-active);
            --button-hover-background: var(--wishlist-button-background-active);
            --button-border: var(--wishlist-button-background-active);
            --button-hover-border: var(--wishlist-button-background-active);

            .ProductWishlistButton-Heart {
                @include product-wishlist-icon-active;
            }
        }

        &.Button {
            border-radius: var(--wishlist-button-radius);
        }

        span {
            display: none;
        }
    }

    &:hover,
    &:focus {
        .ProductWishlistButton-Heart {
            &::before {
                @include after-mobile {
                    color: var(--wishlist-heart-color-hover);
                }
            }
        }

        .ProductWishlistButton-Button_isInWishlist {
            .ProductWishlistButton-Heart {
                &::before {
                    @include after-mobile {
                        color: var(--wishlist-heart-color-active);
                    }
                }
            }
        }
    }

    &-Heart {
        top: 1px;
        @include product-wishlist-icon;

        @include mobile {
            position: relative;
        }
    }
}
