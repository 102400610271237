/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Yehan Virantha <yviranta@kemana.com>
 * http://www.kemana.com
 *
 */
 :root {
    --unit-sale-points-container-width: 798px;
}

.kemanapwa-home-page-main-blocks {
    > div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include mobile {
            display: block;
        }
    }

    .main-banner,
    .main-banner-blocks {
        width: calc(50% - 5px);

        @include mobile {
            width: 100%;
        }
    }

    .main-banner {
        @include desktop {
            min-height: 377px;
        }

        @include desktop-xl {
            min-height: 440px;
        }

        @include desktop-xxl {
            min-height: 499px;
        }
    }

    .main-banner-blocks {
        @include desktop {
            min-height: 377px;
        }

        @include desktop-xl {
            min-height: 440px;
        }

        @include desktop-xxl {
            min-height: 499px;
        }

        .banner-blocks-desk {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            a {
                margin-left: 0;

                &:hover {
                    @include desktop {
                        opacity: .6;
                    }
                }
            }

            .Image {
                padding: 0;
                height: auto;

                @include desktop {
                    overflow: inherit;
                }

                a {
                    &:hover {
                        @include desktop {
                            opacity: .6;
                        }
                    }
                }
            }
        }

        .banner-blocks-mob {
            display: none;
        }

        @include mobile {
            height: auto;
            margin-top: 40px;

            .banner-blocks-desk {
                display: none;
            }

            .banner-blocks-mob {
                display: flex;
                justify-content: space-between;
                min-height: 179px;

                .Image_ratio_square {
                    padding-bottom: 0;
                }

                a {
                    width: 49%;
                }

                .Image {
                    height: auto;
                }
            }
        }

        img {
            object-fit: cover;
            position: static;
            object-position: left;

            @include mobile {
                height: auto !important;
            }
        }
    }
}