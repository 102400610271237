/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.InstallPromptIOS {
    div {
        flex-direction: column;
    }

    &-Heading {
        padding-top: 5px;
    }

    &-Content {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 5px;
    }

    &-Plus {
        @include ios-plus;
    }

    &-Share {
        // @include ios-share;
        @include icon($icon-share-wishlist, before, 1.1rem, $dark3);
        // transform: scale(.8);
        transform-origin: 50% 50%;
        margin: 0 .5rem;
    }
}
