/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-background: transparent;
    --breadcrumbs-active-color: #888;
    --breadcrumbs-color: #{$dark2};
}

.Breadcrumbs {
    background-color: var(--breadcrumbs-background);

    $crumb-padding: 20px;
    $arrow-size: 4px;

    @include print-only {
        display: none;
    }

    @include before-desktop-l {
        --breadcrumbs-background: #fff;

        display: none;
    }

    &-List {
        list-style: none;
        padding: 20px 0;
        height: 40px;
    }
}
