/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --flash-sale-timer-unit-width: 26px;
    --flash-sale-timer-unit-height: 26px;
    --flash-sale-timer-unit-background: #{$white};
    --flash-sale-timer-unit-border: 1px solid #{$grey13};
}

.FlashSaleTimer {
    display: flex;
    flex-wrap: wrap;

    .FlashSaleTimer {
        &-Units {
            display: flex;
            justify-content: center;

            > p {
                padding: 0 2px;
                color: $red2;
                font-size: 2.3rem;
                line-height: 1.8rem;
            }
        }

        &-Unit {
            display: flex;
            flex-wrap: wrap;
            font-size: 1rem;
            line-height: 1.1rem;
            width: 100%;
            justify-content: center;
            align-items: center;
            max-width: 36px;

            @include mobile {
                position: relative;
            }

            &::after {
                content: ':';
                padding: 0 2px;
                color: $red2;
                font-size: 2.3rem;
                line-height: 1.8rem;
                position: absolute;
                right: -4px;
                top: 0;
            }

            span {
                background-color: var(--flash-sale-timer-unit-background);
                color: $red2;
                font-size: 1.4rem;
                font-weight: $font-weight-bold;
                line-height: var(--flash-sale-timer-unit-height);
                width: var(--flash-sale-timer-unit-width);
                height: var(--flash-sale-timer-unit-height);
                border: var(--flash-sale-timer-unit-border);
                display: block;
                margin-bottom: 3px;
                border-radius: 3px;
                text-align: center;
                letter-spacing: 0;
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }
        }

        &-ProgressBarContainer {
            font-size: 1.2rem;
            line-height: 1.4rem;
            letter-spacing: 0;

            @include mobile {
                max-width: 81px;
                width: 100%;
            }
        }

        &-LeftQuantityUpperBar {
            height: .5rem;
            background-color: $grey24;
            border-radius: .6rem;
            overflow: hidden;
            margin-bottom: 6px;

            @include mobile {
                width: 100%;
            }

            @include desktop-xl {
                width: 12.4rem;
            }
        }

        &-LeftQuantityInnerBar {
            height: .5rem;

            &_green {
                background-color: var(--primary-light-color);
            }

            &_orange {
                background-color: $orange2;
            }

            &_red {
                background-color: $red2;
            }
        }
    }

    &.FlashSaleTimer_PDP {
        order: 5;
        flex-basis: 100%;
        margin-top: 2rem;
        display: flex;
        align-items: center;
        background: $grey14;
        padding: 10px;

        @include mobile {
            order: -3;
        }

        @include desktop {
            border-radius: 5px;
            width: fit-content;
        }

        .FlashSaleTimer {
            &-Icon {
                display: flex;
            }

            &-Inner {
                display: flex;
                align-items: center;
                background: $grey14;
                padding: 10px;

                @include desktop {
                    border-radius: 5px;
                    width: fit-content;
                }
            }

            &-Title {
                padding-left: 1rem;
                padding-right: 1rem;
                font-size: 1.2rem;
                line-height: 1.4rem;

                span {
                    display: block;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                }
            }

            &-Unit {
                @include mobile {
                    padding-right: 2px;
                }

                p {
                    @include mobile {
                        padding-left: 1.5px;
                    }
                }
            }

            &-ProgressBarContainer {
                padding-left: 10px;
    
                @include mobile {
                    padding-left: 8px;
                }
            }
        }
    }

    &.FlashSaleTimer_CartPage {
        padding-right: 1rem;

        @include mobile {
            margin-bottom: 5px;
        }

        .FlashSaleTimer {
            &-Inner {
                display: flex;
                flex-wrap: wrap;
            }

            &-Title {
                width: 100%;
                font-size: 1.2rem;
                line-height: 1.4rem;
                margin-bottom: 5px;

                span {
                    &:first-of-type {
                        font-weight: $font-weight-bold;
                    }
                }
            }

            &-ProgressBarContainer,
            &-Icon {
                display: none;
            }
        }
    }

    &.FlashSaleTimer_PLP {
        padding: 10px 0 0;

        .FlashSaleTimer {
            &-Inner {
                display: flex;
                flex-wrap: wrap;
            }

            &-Title {
                width: 100%;
                font-size: 1.2rem;
                text-align: left;
                margin-bottom: 5px;
                order: 1;

                span {
                    &:first-of-type {
                        font-weight: $font-weight-bold;
                    }
                }
            }

            &-Units {
                order: 2;
            }

            &-Unit {
                @include mobile {
                    padding-right: 1.5px;
                }

                @include desktop {
                    padding-right: 2px;
                }

                p {
                    @include mobile {
                        padding-left: 2px;
                    }

                    @include desktop {
                        padding-left: 2.5px;
                    }
                }
            }

            &-ProgressBarContainer {
                margin-bottom: 1rem;
                text-align: left;
                order: 0;

                @include mobile {
                    max-width: 100%;
                }
            }

            &-LeftQuantityUpperBar {
                @include mobile {
                    width: 91%;
                }

                @include desktop {
                    width: 13rem;
                }
            }

            &-Icon {
                display: none;
            }
        }
    }

    &.FlashSaleTimer_CHECKOUT_CART_ITEM {
        @include desktop {
            padding: 5px 0;
        }

        .FlashSaleTimer {
            &-Inner {
                display: flex;
                flex-wrap: wrap;
            }

            &-Title {
                width: 100%;
                font-size: 1.2rem;
                line-height: 1.4rem;
                margin-bottom: 5px;

                span {
                    &:first-of-type {
                        font-weight: $font-weight-bold;
                    }
                }
            }

            &-ProgressBarContainer,
            &-Icon {
                display: none;
            }
        }
    }
}
