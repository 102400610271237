/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --cartOverlay-empty-color: #{$default-primary-dark-color};
    --cartOverlay-promo-text-color: #{$dark3};
    --CartOverlay-top: 6.5rem;
}

.CartOverlay {
    right: 0;
    top: var(--CartOverlay-top);
    bottom: 0;
    width: 360px;;
    padding: 0;
    height: auto;
    transition: 400ms ease-in all;

    @include desktop-xl {
        --CartOverlay-top: 5.4rem;
    }

    &-MinicartWrapper {
        padding: 2rem 1.8rem 1.5rem;
        box-shadow: 0px 2px 6px #00000029;

        .CartItem-Options {
            display: none;
        }

        .CmsBlock-Wrapper {
            p {
                color: $dark3;
            }
        }
    }

    &-Promo,
    .CmsBlock-Wrapper {
        border-radius: 3px;
        padding: 12px 10px;
        color: var(--cartOverlay-promo-text-color);
        border: 1px solid $grey14;
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 1.8rem;
        text-align: center;
    }

    &-Empty {
        text-align: center;

        img {
            width: 140px;
            margin: 0 auto 20px;
        }

        p {
            color: var(--cartOverlay-empty-color);
            max-width: 230px;
            margin: auto;
        }
    }

    &-Header {
        display: none;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 13px;

        h6 {
            font-size: 2rem;
            letter-spacing: $letter-spacing-m3;
        }
    }

    &-Close {
        @include icon($icon-remove, after , 3.4rem, $default-primary-dark-color);

        &:after {
            margin-right: -.9rem;
        }

        span {
            display: none;
        }
    }

    &-Items {
        border-top: 1px solid $green5;
        max-height: 300px;
        overflow-y: auto;
        margin-left: -18px;
        margin-right: -18px;
        padding: 0 10px 0 20px;

        @include desktop {
            @include custom-scroll;
        }

        .CartItem {
            &-Price {
                padding-left: 1.5rem;
                width: 10rem;
                font-weight: $font-weight-regular;
                font-size: 1.2rem;
                line-height: 1.4rem;
                color: $grey12;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            &-Delete {
                margin-left: 4rem;
                margin-right: 1rem;
            }

            &_isEditing {
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    &-Total {
        margin-left: -18px;
        margin-right: -18px;
        padding: 15px 20px;
        background: $green5;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        dt {
            color: $darkblack;
        }

        dd {
            color: $default-primary-dark-color;
            font-weight: $font-weight-bold;
            letter-spacing: $letter-spacing-m3;
        }
    }

    &-Actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 1.5rem;
    }

    &-CartButton {
        padding: 12px 23px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.6px;
        height: 43px;
        margin-right: 1rem;

        &:focus {
            @include desktop {
                text-decoration: none;
            }
        }
    }

    &-CheckoutButton {
        padding: 12px 20px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.42px;
        height: 43px;
        display: flex;
        flex: 1;
        justify-content: center;

        span {
            margin-right: 8px;
            @include checkout-lock-icon;
        }
    }

    &-Discount {
        display: none;
    }
}
