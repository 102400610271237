/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.Topbar {
    background-color: $green3;
    font-size: 1.1rem;
    line-height: 1.9rem;
    letter-spacing: .33px;
    color: $white;
    text-align: center;
    padding: .2rem 0;
    transition: 0.3s;

    @include desktop {
        font-size: 1.3rem;
        line-height: 2.4rem;
        padding: .3rem 0;
        height: 30px;
    }

    strong {
        font-weight: $font-weight-regular;
        text-align: center;
        width: 100%;
    }

    &-container {
        @include fixed-width;
        display: flex;
        justify-content: space-between;

        &.mobile-only {
            > div {
                width: 100%;
                text-align: center;
            }
        }

        @include desktop-l {
            &.mobile-only {
                display: none;
            }
        }

        @include mobile {
            &.desktop-only {
                display: none;
            }
        }

        a {
            color: $green6;
            padding-right: 12px;
            font-size: 1.2rem;

            &::after {
                content: '';
                width: 1px;
                height: 12px;
                background: $green6;
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -6px;
            }

            &:last-child {
                padding-right: 0;

                &::after {
                    content: none;
                }
            }
        }
    }
}

.Header_isHeaderSticky {
    .Topbar {
        opacity: 0;
        height: 0;
        padding: 0;
    }
}