/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: #{$grey13};

    --search-field-visible-background: #{$white};
    --search-input-desktop-border-color: #000;

    --search-area-background: #{$default-primary-dark-color};
    --search-placeholder-color: #{$grey5};
    --search-field-color: #{$default-primary-dark-color};
    --search-clear-background: #{$default-primary-base-color};
    --search-icon-color: #{$white};
    --search-button-bg-color: #{$green4};
    --search-button-hover-bg-color: #{$green3};
}

.SearchField {
    @include before-desktop-l {
        flex-grow: 1;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background: var(--search-area-background);
        width: 100%;
        top: 0;
        padding: 10px 20px;
        left: 0;

        @include mobile {
            background: $white;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
        }
    }

    @include desktop-l {
        max-width: 100%;
        grid-area: search;

        &-Wrapper {
            max-width: 60.5rem;
            float: right;
            width: 100%;

        }
    }

    &_isVisible {
        opacity: 1;
        pointer-events: all;

        .SearchOverlay-Results {
            visibility: visible;
        }
    }

    &-SearchInnerWrapper {
        display: flex;
        position: relative;
    }

    &-Input {
        transition-property: opacity, background-color;
        transition-duration: 200ms;
        margin: 0;
        border-radius: 2px 0 0 2px;
        background: $white;
        height: 3.5rem;
        border: 1px solid var(--search-field-border-color);
        border-right-width: 0;
        width: calc(100% - 4rem);
        padding: 1rem 1.5rem;
        font-size: 1.4rem;
        letter-spacing: $letter-spacing-m;
        color: var(--search-field-color);

        @include mobile {
            border-right-width: 1px;
        }

        &::placeholder {
            color: $grey20;
            letter-spacing: .35px;
            font-weight: $font-weight-light;
            font-size: 1.2rem;
        }

        @include before-desktop {
            max-width: 100%;
        }

        &:focus,
        &:active {
            --input-color: var(--search-field-color);
            border-color: var(--search-field-border-color);
        }
    }

    &-Placeholder {
        font-size: 1.4rem;
        height: min-content;
        left: 1.5rem;
        pointer-events: none;
        position: absolute;
        top: 1.4rem;
        visibility: hidden;
        letter-spacing: $letter-spacing-m;
        color: var(--search-placeholder-color);
        font-style: italic;

        &_isPlaceholderVisible {
            visibility: visible;
        }
    }

    &-SearchIcon,
    &-CloseIcon {
        cursor: pointer;

        @include desktop-l {
            height: 24px;
            width: 16px;
        }
    }

    &-SearchIcon {
        @include search-icon;
        border-radius: 0 2px 2px 0;
        background: var(--search-button-bg-color);
        height: 3.5rem;
        width: 4rem;
        text-align: center;

        &::before {
            line-height: 3.5rem;
            font-size: 1.8rem;
        }

        &:hover {
            @include desktop {
                background: var(--search-button-hover-bg-color);
            }
        }
    }

    &-CloseIcon {
        @include before-desktop-l {
            @include header-back-icon;
            padding: 0 2rem 0 0;
        }

        @include desktop-l {
            @include search-close-icon;
            border-radius: 0 2px 2px 0;
            background: var(--search-button-bg-color);
            height: 3.5rem;
            width: 4rem;
            text-align: center;

            &::before {
                line-height: 3.5rem;
            }

            &:hover {
                @include desktop {
                    background: var(--search-button-hover-bg-color);
                }
            }
        }
    }
}
