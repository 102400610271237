/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

$white: #fff;
$black: #000000;
$default-primary-base-color: #1B5927;
$default-primary-dark-color: #161A17;
$default-primary-light-color: #049D02;
$default-secondary-base-color: #B45310;
$default-secondary-dark-color: #161D2A;
$default-secondary-light-color: #AF1B1F;
$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;

// Colors
$grey1: #5F646B;
$grey2: #F2F2F2;
$grey3: #161D2A;
$grey4: #8A909B;
$grey5: #B1B1B1;
$grey6: #C1C4C9;
$grey7: #C4C6C9;
$grey8: #96999E;
$grey9: #E3E3E3;
$grey10: #E8E9EB;
$grey11: #FAFBFC;
$grey11: #FCFFFD;
$grey12: #818181;
$grey13: #B9B9B9;
$grey14: #EBEBEB;
$grey15: #F9F9F9;
$grey16: #FCFBFA;
$grey17: #20293A;
$grey18: #3F4246;
$grey19: #F5F5F5;
$grey20: #B9B7B5;
$grey21: #D9D9D9;
$grey22: #EBE8E6;
$grey23: #B8BECC;
$grey24: #DBDBDB;

$darkblack:#000000;
$dark1: #161A17;
$dark2: #2E332F;
$dark3: #334035;

$blue1: #E3EAF6;

$red1: #E92328;
$red2: #CC2121;
$red3: #D2495E;

$green1: #4A9539;
$green2: #1B5927;
$green3: #1F662D;
$green4: #049D02;
$green5: #DFEDE2;
$green6: #F0FFF3;
$green7: #DFEFED;
$green8: #049d02;

$orange1: #FFF0E6;
$orange2: #DB5B1C;
$orange3: #F27A00;

$peace: #FFE7D6;
$peach: #FFDDCC;
$peace2: #fffaf7;

$yellow1: #F2B716;
$yellow2: #F0D213;
$yellow3: #FCD334;

// Layout
$layout__max-width: 1320px;

// Box Shadow
$box-shadow1: 0px 1px 5px rgba(0, 0, 0, 0.1);
$box-shadow2: 0px 3px 6px rgba(0, 0, 0, 0.2);
$box-shadow3: 0px 3px 10px rgba(0, 0, 0, 0.1);
$box-shadow4: 0px -3px 20px rgba(0, 0, 0, 0.05);
$box-shadow5: 0px 3px 10px #0000001A;
$box-shadow6: 0px 3px 6px rgba(0, 0, 0, 0.16);
$box-shadow7: 0px 5px 20px rgba(0, 0, 0, 0.05);
$box-shadow8: 0 0 14px 0px rgba(0, 0, 0, 0.15);

// Animation
$transition1: all .5s;

// Icons
$icomoon-font-family: "icomoon-font-family" !default;

// Typography
$font-nunito-sans: 'Nunito Sans', sans-serif;
$font-almarai: 'Almarai', sans-serif;

$base-font-family: $font-almarai;

$font-weight-extrabold: 800;
$font-weight-black: 800;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-regular: 400;
$font-weight-light: 300;

$letter-spacing-xl: 1.5px;
$letter-spacing-l: 0.88px;
$letter-spacing-m5: 0.6px;
$letter-spacing-m4: 0.42px;
$letter-spacing-m3: 0.5px;
$letter-spacing-m2: 0.35px;
$letter-spacing-m: 0.3px;
$letter-spacing-s: 0.7px;

$h1-font-color: var(--primary-dark-color);
$h1-font-weight: $font-weight-bold;
$h1-letter-spacing: $letter-spacing-xl;
$h1-font-size-desktop: 4.8rem;
$h1-font-size-mobile: 2.8rem;
$h1-font-line-height-desktop: 75px;
$h1-font-line-height-mobile: 44px;

$h2-font-color: var(--primary-dark-color);
$h2-font-weight: $font-weight-bold;
$h2-letter-spacing: $letter-spacing-l;
$h2-font-size-desktop: 30px;
$h2-font-size-mobile: 24px;
$h2-font-line-height-desktop: 47px;
$h2-font-line-height-mobile: 38px;

$h3-font-color: var(--primary-dark-color);
$h3-font-weight: $font-weight-extrabold;
$h3-letter-spacing: $letter-spacing-m5;
$h3-font-size-desktop: 24px;
$h3-font-size-mobile: 20px;
$h3-font-line-height-desktop: 27px;
$h3-font-line-height-mobile: 22px;

$h4-font-color: $dark3;
$h4-font-weight: $font-weight-bold;
$h4-letter-spacing: $letter-spacing-m5;
$h4-font-size-desktop: 20px;
$h4-font-size-mobile: 16px;
$h4-font-line-height-desktop: 31px;
$h4-font-line-height-mobile: 25px;

$h5-font-color: var(--primary-dark-color);
$h5-font-weight: $font-weight-bold;
$h5-letter-spacing: $letter-spacing-m4;
$h5-font-size-desktop: 16px;
$h5-font-size-mobile: 16px;
$h5-font-line-height-desktop: 25px;
$h5-font-line-height-mobile: 25px;

$h6-font-color: var(--primary-dark-color);
$h6-font-weight: $font-weight-regular;
$h6-letter-spacing: $letter-spacing-s;
$h6-font-size-desktop: 14px;
$h6-font-size-mobile: 14px;
$h6-font-line-height-desktop: 16px;
$h6-font-line-height-mobile: 16px;

$subtitle-primary-font-color: var(--primary-dark-color);
$subtitle-primary-font-size: 12px;
$subtitle-primary-font-line-height: 14px;
$subtitle-primary-font-weight: $font-weight-regular;
$subtitle-primary-letter-spacing: $letter-spacing-m;
$subtitle-primary-margin: 0 0 20px;

$subtitle-secondary-font-color: var(--primary-dark-color);
$subtitle-secondary-font-size: 15px;
$subtitle-secondary-font-line-height: 20px;
$subtitle-secondary-font-weight: $font-weight-bold;
$subtitle-secondary-letter-spacing: $letter-spacing-m2;
$subtitle-secondary-margin: 0 0 20px;

$paragraph-font-family: $base-font-family;
$paragraph-font-color: var(--primary-dark-color);
$paragraph-font-weight: $font-weight-light;
$paragraph-letter-spacing: $letter-spacing-m;
$paragraph-font-size-desktop: 12px;
$paragraph-font-size-mobile: $paragraph-font-size-desktop;
$paragraph-font-line-height-desktop: 18px;
$paragraph-font-line-height-mobile: $paragraph-font-line-height-desktop;
