/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductList {
  &-ProductsMissing {
    text-align: left;
    padding-left: 0;
    max-width: 738px;
    padding-top: 20px;

    @include mobile {
      padding: 0 10px;
    }

    h2 {
        letter-spacing: $letter-spacing-s;
    }

    p {
      font-size: 14px;
      letter-spacing: .35px;
      font-weight: $font-weight-regular;
      padding-top: 10px;
    }
  }
}

.CategoryDetails-Description {
  .SearchPage-Heading {
    span {
      color: $dark2;
    }
  }
}
