/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CategoryPagination {
    display: flex;
    justify-content: center;
    // min-height: 56px;
    margin: auto;
    align-items: center;
    z-index: 2;
    width: min-content;

    @include desktop {
        order: 1;
    }

    @include mobile {
        padding-bottom: 0;
        display: none;
    }

    &-Icon {
        cursor: pointer;
        margin: 0;
        width: var(--category-pagination-width);
        height: var(--category-pagination-height);
        line-height: var(--category-pagination-height);
        display: flex;
        align-items: center;
        justify-content: center;

        // @include icon($icon-feather-left, before, 12px, var(--header-icon-text-color));

        @include desktop {
            &:hover,
            &:focus {
                --header-color: var(--color-pagination-link-active-text);
            }
        }
    }

    &-ListItem {
        margin-bottom: 0;
        padding-left: 0;
        min-width: var(--category-pagination-width);
        display: block;

        &::before {
            content: '';
        }

        &:first-child {
            .CategoryPaginationLink {
                margin-right: 1.5rem;
            }

            @include mobile {
                padding-left: 0;
            }
        }

        &:last-child {
            .CategoryPaginationLink {
                margin-left: 1.5rem;
            }

            margin-bottom: 0;
        }
    }
}
