/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-compare-button-width: 35px;
    --product-compare-size: 15px;
    --product-compare-border: #{$grey7};
    --product-compare-border-hover: var(--primary-dark-color);
    --product-compare-background-active: #{$grey9};
}

.ProductCompareButton {
    --loader-scale: .6;
    --button-padding: 0;

    --button-color: var(--product-compare-border);
    --button-background: transparent;
    --button-border: var(--product-compare-border);

    --button-hover-color: var(--product-compare-border-hover);
    --button-hover-background: transparent;
    --button-hover-border: var(--product-compare-border-hover);

    vertical-align: middle;
    display: inline-block;

    &-Button {
        --button-color: var(--product-compare-border);

        width: var(--product-compare-button-width);
        height: var(--product-compare-button-width);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @include mobile {
            position: relative;
        }

        > span {
            display: none;
        }
    }

    &-Icon {
        @include product-compare-icon;

        &::before {
            font-size: var(--product-compare-size);
            line-height: var(--product-compare-size);
        }

        margin-top: 2px;
    }

    &:hover {
        .ProductCompareButton-Icon {
            &::before {
                @include after-mobile {
                    color: var(--product-compare-border-hover);
                }
            }
        }
    }

    // &_isGrey {
    //     --button-color: var(--product-compare-button-grey-color);
    //     --button-background: var(--product-compare-button-grey-light-color);
    //     --button-border: var(--product-compare-button-grey-light-color);
    //     --button-hover-background: var(--product-compare-button-grey-light-color);
    //     --button-hover-border: var(--product-compare-button-color);

    //     @include desktop {
    //         &:hover {
    //             --button-color: var(--product-compare-button-color);
    //         }
    //     }
    // }

    &_isActive {
        .ProductCompareButton-Button {
            --button-color: var(--product-compare-border-hover);
        }

        .ProductCompareButton-Icon {
            &::before {
                color: var(--product-compare-border-hover);
            }
        }
    }
}

.ProductCompareAddSuccessPopup {
    .Popup-Content {
        @include mobile {
            --popup-content-padding: 1rem 1rem 2rem;
        }

        @include desktop {
            padding: 0;
            max-height: 418px;
        }

        .Popup-Header {
            background-color: $orange2;
            margin: 0;

            h3 {
                color: $white;
                margin-top: 0;
                letter-spacing: .5px;
                line-height: 31px;
                padding: 29px 20px;
                text-transform: none;

                @include compare-popup-icon;

                &:before {
                    margin-right: 10px;
                    top: 4px;
                    position: relative;
                }
            }
        }
    }

    .ProductCompareAddSuccessPopup {
        text-align: center;
        font-size: 12px;
        color: $default-primary-dark-color;
        letter-spacing: .3px;
        line-height: 18px;
        padding: 0 10px;

        &-wrapper {
            text-align: center;

            .ProductCompareAddSuccessPopup-productImageContainer {
                margin-top: 20px;

                img {
                    width: 150px;
                    border: 1px solid $grey14;
                    border-radius: 5px;
                    margin-bottom: 12px;
                }
            }

            .ProductCompareAddSuccessPopup-viewComparisonButtonLink {
                margin-bottom: 30px;
                margin-top: 30px;

                @include mobile {
                    display: block;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }

        &-productName {
            max-width: 368px;
            margin: 0 auto;
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }
}